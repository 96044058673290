import style from './MapaResumo.module.css'
import './MapaResumo.css'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Col, Container, Nav, Row } from 'react-bootstrap'
import { TemaContext } from '../../common/context/TemaContext';
import { LinguagemContext } from '../../common/context/LinguagemContext';
import { UsuarioContext } from '../../common/context/UsuarioContext';
import { useNavigate} from 'react-router-dom';
import { useTranslation} from 'react-i18next';
import Translator from '../I18n/Translator';
import L from 'leaflet';
import { Box, FormControl, InputLabel, MenuItem, Select, Slider, TextField, OutlinedInput, Stack, Chip, Autocomplete, Tabs, Paper, Tab } from '@mui/material';
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import Retangulo from './Retangulo';
import Moment from 'react-moment';
import 'chartjs-adapter-moment';
import { Bar, Line } from 'react-chartjs-2';
import PopupGrafico from '../PopupGrafico';
import Footer from '../Footer';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { t } from 'i18next';
import Tabela from '../Tabela';
import Switch from '@mui/material/Switch';
import iconAmareloUrl from './icon1.png'; // Importe o ícone de imagem 1
import iconVerdeUrl from './icon2.png'; // Importe o ícone de imagem 2
import iconVermelhoUrl from './icon3.png'; // Importe o ícone de imagem 3
import { set } from 'date-fns';
import TabNav from '../TabNav';

var teste_data = "";
var posicao = localStorage.getItem('posicaoScroll');

var texto_grafico_dark = "#fff";
var texto_grafico_light = "#000";
var fontGrafico = 10;

var opacidadeMunicipio = 1.0;

var alterandoData = false;
var listBindPopup = [];
var cod_estados = [
    {"UF":"AC",
    "COD": 12, "NOME":"Acre"},
    {"UF":"AL",
    "COD": 27, "NOME":"Alagoas"},
    {"UF":"AP",
    "COD": 16, "NOME":"Amapá"},
    {"UF":"AM",
    "COD": 13, "NOME":"Amazonas"},
    {"UF":"BA",
    "COD": 29, "NOME":"Bahia"},
    {"UF":"CE",
    "COD": 23, "NOME":"Ceará"},
    {"UF":"DF",
    "COD": 53, "NOME":"Distrito Federal"},
    {"UF":"ES",
    "COD": 32, "NOME":"Espírito Santo"},
    {"UF":"GO",
    "COD": 52, "NOME":"Goiás"},
    {"UF":"MA",
    "COD": 21, "NOME":"Maranhão"},
    {"UF":"MT",
    "COD": 51, "NOME":"Mato Grosso"},
    {"UF":"MS",
    "COD": 50, "NOME":"Mato Grosso do Sul"},
    {"UF":"MG",
    "COD": 31, "NOME":"Minas Gerais"},
    {"UF":"PA",
    "COD": 15, "NOME":"Pará"},
    {"UF":"PB",
    "COD": 25, "NOME":"Paraíba"},
    {"UF":"PR",
    "COD": 41, "NOME":"Paraná"},
    {"UF":"PE",
    "COD": 26, "NOME":"Pernambuco"},
    {"UF":"PI",
    "COD": 22, "NOME":"Piauí"},
    {"UF":"RJ",
    "COD": 33, "NOME":"Rio de Janeiro"},
    {"UF":"RN",
    "COD": 24, "NOME":"Rio Grande do Norte"},
    {"UF":"RS",
    "COD": 43, "NOME":"Rio Grande do Sul"},
    {"UF":"RO",
    "COD": 11,"NOME":"Rondônia"},
    {"UF":"RR",
    "COD": 14,"NOME":"Roraima"},
    {"UF":"SC",
    "COD": 42,"NOME":"Santa Catarina"},        
    {"UF":"SP",
    "COD": 35,"NOME":"São Paulo"},
    {"UF":"SE",
    "COD": 28,"NOME":"Sergipe"},
    {"UF":"TO",
    "COD": 17,"NOME":"Tocantins"},
];



const MapaResumo = () => {
    let link_gripe = "#";
    let dengue = true;

    var zoomPorClique = false;
    const { t } = useTranslation();
    const navigate = useNavigate();
    posicao = localStorage.getItem('posicaoScroll');
    var nomes_estados = (require('./Dados/estados.json'));
    /*var dados_municipios_semanal = (require('./BA/dados.json'));*/

    const [valorTab, setValorTab] = useState(0);

    const [dataCache, setDataCache] = useState(null);

    //useContext
    const {theme} = useContext(TemaContext);
    const {language} = useContext(LinguagemContext);
    const {logado} = useContext(UsuarioContext);

    var corSelecao = (theme === "dark" ? "white" : "#757575");
    var corLinhaMarcaoGrafico = (theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(94, 94, 94, 1)");
    const txtTotalAtendimentos = useRef(t("dashboard.graficos.titulo_atendimento_totais"));
    const txtTotalGripal = useRef(t("dashboard.graficos.titulo_atendimento_gripais"));
    const txtTaxaAtendimentos = useRef(t("dashboard.graficos.legenda_mm.2"));
    const txtNomeMunicipio = useRef(t("dashboard.cidade"));
    const txtNomeEstado = useRef(t("dashboard.estado"));

    const dados_estados_por_data = useRef(null);
    const [dados_municipios_por_data, setDados_Municipios_Por_Data] = useState(null);
    const [dados_municipios, setDados_Municipios] = useState(null);
    const [dados_sinais, setDados_Sinais] = useState(null);
    const [totalSinais, setTotalSinais] = useState(null);
    const [totalSinaisAnt, setTotalSinaisAnt] = useState(null);
    const [dadosTotalAtendimentosGripais, setDadosTotalAtendimentosGripais] = useState(null);
    const [filtrandoMunicipio, setFiltrandoMunicipio] = useState(false);
    const [dados_municipio_selecionado, setDados_Municipio_Selecionado] = useState(null);
    const [dados_pais, setDados_Pais] = useState(null);

    const [link_zika, setLink_Zika] = useState("https://aesoparbovdev.outerlamce.com/");

    const [dados_totais, setDados_Totais] = useState(null);

    const [cidades_labels, setCidades_Labels] = useState([{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}]);

    const defaultPropsMunicipio = {
        options: cidades_labels,
        getOptionLabel: (option) => option.label,
    };

    const val_prob_gro_trend = [
        "≤75",
        ">75 e ≤95",
        ">95"
    ];

    let val_OTC = [
        t("dashboard.sem_aviso"),
        t("dashboard.aviso")
    ];

    var estados_labels = [
        {label:t("dashboard.todos")},
        {label:"Acre"},
        {label:"Alagoas"},
        {label:"Amapá"},
        {label:"Amazonas"},
        {label:"Bahia"},
        {label:"Ceará"},
        {label:"Distrito Federal"},
        {label:"Espírito Santo"},
        {label:"Goiás"},
        {label:"Maranhão"},
        {label:"Mato Grosso"},
        {label:"Mato Grosso do Sul"},
        {label:"Minas Gerais"},
        {label:"Pará"},
        {label:"Paraíba"},
        {label:"Paraná"},
        {label:"Pernambuco"},
        {label:"Piauí"},
        {label:"Rio de Janeiro"},
        {label:"Rio Grande do Norte"},
        {label:"Rio Grande do Sul"},
        {label:"Rondônia"},
        {label:"Roraima"},
        {label:"Santa Catarina"},        
        {label:"São Paulo"},
        {label:"Sergipe"},
        {label:"Tocantins"},
    ];

    var defaultProps = {
        options: estados_labels,
        getOptionLabel: (option) => option.label,
    };
                                                                    
    var [selectedVal_Prob_Gro_Trend, setSelectedVal_Prob_Gro_Trend] = useState(val_prob_gro_trend/*.filter((item) => item !== "≤75")*/);
    var [selectedOTC, setSelectedOTC] = useState(val_OTC);


    const corMapa3 = useMemo(() => { //vermelho
        return "#fe3a3a"; /*return "#e6585c"; return "#b61d39"; return "#d84765"; "#b61d39";*/ /*"#8b0000";*/
    }, []); 
    const corMapa3Borda = useMemo(() => { //vermelho
        return "#fe3a3a"; /*return "#e6585c"; return "#b61d39"; return "#d84765"; "#b61d39";*/ /*"#8b0000";*/
    }, []); 
    const corMapa3_Escuro = useMemo(() => { //vermelho
        return "rgba(254, 58, 58, 0.5)"; /*return "#e6585c"; return "#b61d39"; return "#d84765"; "#b61d39";*/ /*"#8b0000";*/
    }, []);
    const corMapa2 = useMemo(() => { //amarelo
        return "#fcfe52"; /*return "#e8c371"; return "#fefebc";* "#fea0ac";*//*"#ef738b";*/
    }, []); 

    const corMapa1 = useMemo(() => {  //verde
        return "#b9fd82"; /*return "#319d8f"; return "#399785"; return "#9edba4"; "#ffffe0";*/ /*"#ffd1c9";*/
    }, []); 
    const corMapa1Borda = useMemo(() => {  //verde
        return "#5ec910"/*"#66e602"*//*"#9dfd51"*/; /*return "#319d8f"; return "#399785"; return "#9edba4"; "#ffffe0";*/ /*"#ffd1c9";*/
    }, []);
    const  corMapa1BordaDark = useMemo(() => {  //verde
        return "#6c904f";
    }, []);
    const corMapa4Excesso = useMemo(() => { 
        return "#155E95"; 
    }, []);

    const corMapa1Excesso = useMemo(() => {  
        return "#94C2D7"; 
    }, []); 
    var corLinhaMarcaoGrafico = (theme === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(94, 94, 94, 1)");

    const corMapaNaoApto = useMemo(() => {  
        return "#9E9E9E"; 
    }, []); 

    const corMapaNaoAptoDark = useMemo(() => {  
        return "#757575"; 
    }, []); 

    const corMapaNoData = useMemo(() => {  
        return "#efefef"; 
    }, []); 

    const corMapaNoDataDark = useMemo(() => {  
        return "#3f3f41"; 
    }, []); 

    const corMapaNaoAptoBorda = useMemo(() => {  
        return "#878787"; 
    }, []); 

    const corMapaNaoAptoBordaDark = useMemo(() => {  
        return "#A2a2a2"; 
    }, []); 

    const borderColor2 = useMemo(() => {
        return "rgb(245 , 75 , 9)";
    }, []); 
    const backgroundColor2 = useMemo(() => { 
        return "rgba(245 , 75 , 9, 0.5)";
    }, []); 
    const corBackgroundAtendimentosGripais = useMemo(() => {
        return 'rgba(47, 180, 239, 0.5)';
    }, []); 

    const legenda = [];
    var legendaSemana = [];
    const legend = useRef(null);
    const espacoLegendaGrafico = "      ";

    const [indice, setIndice] = useState("ENS");

    const qtdLayers =  useRef(0);

    const valorNil = "nil";

    /*Variáveis Tabela*/
    const [campoOrdem, setCampoOrdem] = useState("prob_gro_trend_ivas");
    const [decrescente, setDecrescente] = useState(true);
    const [markers, setMarkers] = useState([]);
    /*Fim Variáveis Tabela*/

    const [valueRangeData, setValueRangeData] = useState([0,0]);

    const timeOutFiltraMunicipio = useRef(null);
    const timeOutFiltraEstado = useRef(null);

    //funções
    const comparaDataInicio = (data1, data2) => {        
        if(data1 === null || data2 === null)
            return false;
        return data1 >= data2;
    }

    const getIdxDataInicio = (datas_local) => {
        var idx = 0;
        for (let idx_data = 0; idx_data < datas_local.length; idx_data++) {
            if (comparaDataInicio(new Date(datas_local[idx_data]), dataInicial.current)) {
                idx = idx_data;
                break;
            }
        }
        return idx;
    }

    const preparaInt = (valor) => {
        var valor_temp = valor + "";
        valor_temp = valor_temp.replace(".", "");
        valor_temp = valor_temp.replace(",", "");
        return valor_temp;
    }

    const preparaIntTrunc = (valor) => {
        var valor_temp = valor + "";
        valor_temp = valor_temp.replace(".", "");
        valor_temp = valor_temp.split(",");
        if(Array.isArray(valor_temp)){
            return valor_temp[0];
        }
        return valor_temp;
    }

    const fixaCasasDecimais = (valor, casasDecimais) => {
        if(valor === valorNil) 
            return valorNil;
        return parseFloat(valor).toFixed(casasDecimais).replace(',', '.');
    }

    const isNumeric = (value) => {
        return /^\d+(?:\.\d+)?$/.test(value); 
    }

    function preparaMesAno(data) {
        return data.substring(5, 7) + "/" + data.substring(0, 4);
    }

    function getSiglaEstado(cod_ibge_local){
        for(let i = 0; i < cod_estados.length; i++){            
            if(parseInt(cod_estados[i]["COD"]) === parseInt(cod_ibge_local.toString().substring(0, 2))){
                return cod_estados[i]["UF"];
            }
        }
    }

    function getSiglaEstadoByNome(nome_estado_local){
        for(let i = 0; i < cod_estados.length; i++){            
            if(cod_estados[i]["NOME"] === nome_estado_local){
                return cod_estados[i]["UF"];
            }
        }
    }

    const getSemana = () => {
        fetch('dados_semanas.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
        .then(CheckError)
        .then(function (myJson) {
            semanas_epidemiologicas.current = myJson;                
        });
    }


    function CheckError(response) {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
    }

    function getCodIbgeLayerShapefile(layer) {
        return (layer["feature"]["properties"]["CD_MUN"]);
    }

    const getNomeCidadeWhereCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local)){
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if(elementLoop !== undefined){
                        let cod_temp = parseInt(elementLoop["feature"]["properties"]["CD_MUN"]);
                        if(elementLoop["feature"]["properties"]["CD_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  elementLoop["feature"]["properties"]["NM_MUN"];
                            break;
                        }
                    } else {
                        retorno = cod_ibge_local;
                    }
                }
            } 
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    const getNomeEstadoByCodIbge = (cod_ibge_local) => {
        let retorno = null;
        if(isNumeric(cod_ibge_local) && shapeFileBrasil.current !== null && shapeFileBrasil.current["_layers"] !== null){
            /*Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
                let cod_temp = parseInt(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["CD_UF"]);
                if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["CD_UF"] === cod_ibge_local || cod_temp === cod_ibge_local){
                   retorno =  shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"];
                }                
            });*/

            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const element = shapeFileBrasil.current["_layers"][key];
                    if(element !== undefined){
                        let cod_temp = parseInt(element["feature"]["properties"]["CD_UF"]);
                        if(element["feature"]["properties"]["CD_UF"] === cod_ibge_local || cod_temp === cod_ibge_local){
                            retorno =  element["feature"]["properties"]["NM_UF"];
                            break;
                        } 
                    } else {
                        retorno = cod_ibge_local;
                    }
                }
            }  
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    function onEachFeatureEstado(feature, layer) {
        layer.on({
            click: () => {
                if(cod_ibge === getCodIbgeLayerShapefile(layer)){
                    map.current.fitBounds(layer.getBounds().pad(1));
                    return;
                }

                setLoading(true);
   
                //resetShapeFile(estadoSelecionado, municipioSelecionado, true);

                let siglaEstadoSelecionada = feature["properties"]["SIGLA_UF"];

                let codEstadoSelecionado = getCodEstadoBySigla(siglaEstadoSelecionada);

                let nomeEstadoSelecionado = getNomeEstadoByCodIbge(codEstadoSelecionado);

                if(estadoSelecionado !== nomeEstadoSelecionado){
                    carregaMunicipiosPorEstado(siglaEstadoSelecionada);

                    setEstadoSelecionado(nomeEstadoSelecionado);
                }

                setMunicipioSelecionado(layer['feature']['properties']['NM_MUN']/*getNomeCidadeWhereCodIbge(getCodIbgeLayerShapefile(layer))*/);
                setCod_Ibge(getCodIbgeLayerShapefile(layer));

                carregaTabelaPorEstado(getCodEstadoByName(estadoSelecionado));
                layer.setStyle({ weight: weightSelecao, color: "black", fillOpacity: opacidadeMunicipio  });
                zoomPorClique = true;
                map.current.fitBounds(layer.getBounds().pad(1));
            },
        });
    }
  
    function onEachFeatureBrasil(feature, layer) {
        layer.on({
            click: () => {
                setLoading(true);
                setEstadoSelecionado(layer["feature"]["properties"]["NM_UF"]);
                setMunicipioSelecionado(t("dashboard.todos"));
                setCod_Ibge(t("dashboard.todos"));
                setDados_Municipio_Selecionado(null);

                map.current.fitBounds(layer.getBounds());
            },
        });
    }

    function getCidades(somenteNomes) {
        var nomes_municipios = [];

        if(somenteNomes){
            nomes_municipios.unshift(
                t("dashboard.todos")
            );
            nomes_municipios.sort((a, b) => a.localeCompare(b));
        } else {
            nomes_municipios.unshift({
                "cod_ibge": 0,
                "municipio": t("dashboard.todos")
            });
            nomes_municipios.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
        }

        return nomes_municipios;
    }

    const getEstados = () => { 
        let nomes_estados_tmp = [];
        
        for (let i=0; i < nomes_estados.length; i++){
            nomes_estados_tmp.push(nomes_estados[i]);
        }

        nomes_estados_tmp.unshift({
            "UF": "All",
            "ESTADO": t("dashboard.todos")
        });         
        
        return nomes_estados_tmp;
    }

    const filtrarEstado = (estado) => {
        if(estado === t("dashboard.todos"))
            return;
        
        /*Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
            if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"] === estado){
                map.current.fitBounds(shapeFileBrasil.current["_layers"][shape].getBounds());
            }
        });*/

        const keys = Object.keys(shapeFileBrasil.current["_layers"]);
        if (keys.length > 0) {
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                let element = shapeFileBrasil.current["_layers"][key];
                if(element !== undefined){
                    if(element["feature"]["properties"]["NM_UF"] === estado){
                        map.current.fitBounds(element.getBounds());
                        break;
                    }
                }
            }
        } 

        setLoading(false);
    };

    
    const mudarData = (idx, datas_local) => {
        setLoading(true);
        if (alterandoData && !datas_local)
            return;

        if (idx > datas_local.length - 1) {
            idx = 0;
        } else if (idx < 0) {
            idx = datas_local.length - 1;
        }

        if(map.current  !== null)
            map.current.closePopup();

        alterandoData = true;

        setIdxDataSelecionada(idx);
        setDataSelecionada(datas_local[idx]);
        setSemanaSelecionada(semanas[idx]);
        setInicioSemanaSelecionada(inicioSemanas[idx]);
        setFimSemanaSelecionada(fimSemanas[idx]);

        alterandoData = false;
    }

    const getLastYear = (dt) => {
        let dt_local = new Date(dt);
        dt_local.setFullYear(dt_local.getFullYear() - Number(1));
        return dt_local;
    }

    const handleChangeSelectData = (event) => {
        if(event.target.value > (datas.length -1)){
            setIdxDataSelecionada(datas.length -1);
            setDataSelecionada(datas[datas.length -1]);
            setSemanaSelecionada(semanas[semanas.length -1]);
            setInicioSemanaSelecionada(inicioSemanas[inicioSemanas.length -1]);
            setFimSemanaSelecionada(fimSemanas[fimSemanas.length -1]);
        } else {
            setIdxDataSelecionada(event.target.value);
            setDataSelecionada(datas[event.target.value]);
            setSemanaSelecionada(semanas[event.target.value]);
            setInicioSemanaSelecionada(inicioSemanas[event.target.value]);
            setFimSemanaSelecionada(fimSemanas[event.target.value]);
        }
    };

    const handleChangeMunicipioTabela = (cod_ibge) => {
        if (map.current !== null)
            map.current.closePopup();
        if(!isAllSelect(estadoSelecionado)){
            setCod_Ibge(cod_ibge.toString());
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(cod_ibge.toString()));
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                if (cod_ibge.toString() === getCodIbgeShapefile(shapeFileMunicipios.current, shape)) {
                    shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightSelecao, color: "black", fillOpacity: 1.0 });
                    map.current.fitBounds(shapeFileMunicipios.current["_layers"][shape].getBounds().pad(1));
                }
            });
        } else {
            let ufEstado = "";
            for(let i=0; i < cod_estados.length; i++){
                if(cod_ibge.toString().startsWith(cod_estados[i]["COD"])){
                    ufEstado = cod_estados[i]["UF"];
                    break;
                }
            }
            if(ufEstado !== ""){
                for(let i = 0; i < listaEstados.length; i++){
                    if(listaEstados[i]["UF"] === ufEstado){
                        setLoading(true);
                        selecionaMunicipio.current = cod_ibge.toString();
                        setTimeout(() => {
                            setEstadoSelecionado(listaEstados[i]["ESTADO"]);
                        },1000);
                        break;
                    }
                }
            }
        }
    }

    const handleChangeMunicipioNome = (nome_municipio) => {
        if (map.current !== null)
            map.current.closePopup();


        if(isAllSelect(nome_municipio)){
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                let corBordaAnt = shapeFileMunicipios.current["_layers"][shape]["options"]["color"];
                if(corBordaAnt === "#dddcdf"){
                    corBordaAnt = "black"; 
                }
                shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightNormal, color: corBordaAnt, fillOpacity: 1.0 });
            });

            setCod_Ibge(nome_municipio);
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
            setDados_Municipio_Selecionado(null);
        } else {
            setCod_Ibge(nome_municipio);
            setMunicipioSelecionado(getNomeCidadeWhereCodIbge(nome_municipio));
            
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    if (nome_municipio === getCodIbgeShapefile(shapeFileMunicipios.current, key)) {
                        map.current.fitBounds(elementLoop.getBounds().pad(1));
                        break;
                    }
                }
            } 
        }
    };

    const handleChangeIndicador = (event) => {
        setIndice(event.target.value);
    };

    window.onscroll = function (e) {
        posicao = window.scrollY;
        localStorage.setItem('posicaoScroll', JSON.stringify(posicao));
    }

    const getCodIbgeCidadeWhereNome = (cod_ibge_local) => {
        let retorno = null;
        if(!isNumeric(cod_ibge_local) || (cod_ibge_local.length === 6)){
              
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLoop = shapeFileMunicipios.current["_layers"][key];
                    let cod_temp = elementLoop["feature"]["properties"]["CD_MUN"].substring(0, 6);
                    if(elementLoop["feature"]["properties"]["NM_MUN"] === cod_ibge_local || cod_temp === cod_ibge_local){
                        retorno =  elementLoop["feature"]["properties"]["CD_MUN"];
                        break;
                    } 
                }
            } 
        }
        else {
            retorno = cod_ibge_local;
        }
        return retorno;
    }

    const getCodIbgeShapefile = (shapefile, shape) => {
        let valor = shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"];
        if(typeof shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"] === "number"){
            valor = shapefile["_layers"][shape]["feature"]["properties"]["CD_MUN"].toString();
        }
        return (valor);
    }

    const isAllSelect = (variavel) => {
        return variavel === "All" || variavel === "Todos";
    }

    const criaPopups = (tempMakersMediaMovel) => {
        listBindPopup = [];
        for (let i = 0; i < tempMakersMediaMovel.length; i++) {
            var naoFoiPossivelCalcular = false;
            if(tempMakersMediaMovel[i]["cor_media_movel"] === "#545453" && tempMakersMediaMovel[i]["variacao_media_movel"] === "0.00"){
                naoFoiPossivelCalcular = true;
            }                

            listBindPopup.push({
                "cod_ibge": tempMakersMediaMovel[i]["cod_ibge"],
                "conteudo":`<div class="custom-popup">` + tempMakersMediaMovel[i]["variacao_media_movel"] === "NaN" || tempMakersMediaMovel[i]["variacao_media_movel"] === null ? 
                    `<h6>${getSiglaEstado(tempMakersMediaMovel[i]["cod_ibge"])} - ${tempMakersMediaMovel[i]["municipio"]}</h6> ${t("dashboard.sem_informacao")}` 
                    : 
                    `<h6>${getSiglaEstado(tempMakersMediaMovel[i]["cod_ibge"])} - ${tempMakersMediaMovel[i]["municipio"]}</h6>  
                                          ${t("dashboard.probabilidade_crescimento")} : ${fixaCasasDecimais(tempMakersMediaMovel[i]["prob_gro_trend_ivas"]*100, 2) + "%"}
                                          <br class="br-custom"/>  
                                          ${t("dashboard.graficos.titulo_atendimento_gripais")} :  ${tempMakersMediaMovel[i]["total_gripal"]}
                                          <br class="br-custom"/>
                                          ${t("dashboard.graficos.titulo_atendimento_totais")} : ${tempMakersMediaMovel[i]["total_geral"]}
                                          <br class="br-custom"/>
                                          ${t("dashboard.dqi")} :  ${tempMakersMediaMovel[i]["dqi"].toLowerCase() === "apto" ? t("dashboard.apto") : t("dashboard.nao_apto")}
                                          <br class="br-custom"/>
                                          ${t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(tempMakersMediaMovel[i]["semana"])} 
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          ${t("dashboard.mesAno").replace(":", "") + ": " + preparaMesAno(tempMakersMediaMovel[i]["data"])}
                                          </div>`
            });

        }

       /* Object.keys(shapeFileEstado.current["_layers"]).forEach((shape) => {
            for (let i = 0; i < listBindPopup.length; i++) {
                if (parseInt(getCodIbgeShapefile(shapeFileEstado.current, shape)) === parseInt(listBindPopup[i]["cod_ibge"])) {
                    if(shapeFileEstado.current["_layers"][shape].getPopup()){
                        shapeFileEstado.current["_layers"][shape].getPopup().setContent(listBindPopup[i]["conteudo"]);
                        shapeFileEstado.current["_layers"][shape].getPopup().update();
                    } else {
                        shapeFileEstado.current["_layers"][shape].bindPopup(listBindPopup[i]["conteudo"], {autoPan:false, className: 'popup-with-no-events'});
                    }

                    shapeFileEstado.current["_layers"][shape].on('mouseover', function (e) {
                        this.openPopup();
                    });
                    shapeFileEstado.current["_layers"][shape].on('mouseout', function (e) {
                        this.closePopup();
                    });
                    break;
                }
            }
        });*/

        Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
            for (let i = 0; i < listBindPopup.length; i++) {
                if (parseInt(getCodIbgeShapefile(shapeFileMunicipios.current, shape)) === parseInt(listBindPopup[i]["cod_ibge"])) {
                    if(shapeFileMunicipios.current["_layers"][shape].getPopup()){
                        shapeFileMunicipios.current["_layers"][shape].getPopup().setContent(listBindPopup[i]["conteudo"]);
                        shapeFileMunicipios.current["_layers"][shape].getPopup().update();
                    } else {
                        shapeFileMunicipios.current["_layers"][shape].bindPopup(listBindPopup[i]["conteudo"], {autoPan:false, className: 'popup-with-no-events'});
                    }
                    shapeFileMunicipios.current["_layers"][shape].on('mouseover', function(e){ 
                        var popup = e.target.getPopup();
                        popup.setLatLng(e.latlng).openOn(map.current); 
                    });
                    shapeFileMunicipios.current["_layers"][shape].on('mousemove', function(e){ 
                        var popup = e.target.getPopup();
                        popup.setLatLng(e.latlng).openOn(map.current);
                    });
                    shapeFileMunicipios.current["_layers"][shape].on('mouseout', function(){ 
                        this.closePopup(); 
                    });
                
                    break;
                }
            }
        });
    }

    const getDadosPorData = (dt, linguagem, pintaCirculo) => {
        if (dt !== undefined && dt !== null && dt !== 0) {

            let tempMakersMediaMovel = [];
            var path_dir = "semanal";
            fetch('Dados/dados_estados/datas/dados_' + dt.replace(" 00:00:00", "") + '.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    //Limpa pontos anteriores
                    return response.json();
                })
                .then(function (myJson) {
                    dados_estados_por_data.current = myJson;
                    if(isAllSelect(estadoSelecionado)){
                  
                        let excesso_brasil = 0;
                        if(dados_estados_por_data.current != null){
                            for (let x = 0; x < dados_estados_por_data.current["estados"].length; x++) {
                                excesso_brasil += dados_estados_por_data.current["estados"][x]["exc"];
                            }
                        }
                        setExcesso(excesso_brasil);
                    }
                    
                    if(isAllSelect(municipioSelecionado)){
                        let codigo_estado = getCodEstadoByName(estadoSelecionado);

                        let total_gripal_ant = 0;
                        let total_geral_ant = 0;
                        for (let index = 0; index < dados_estados_por_data.current["estados"].length; index++) {
                            const element = dados_estados_por_data.current["estados"][index];
                           // console.log(element);
                            if(element['cod_ibge'].toString() === codigo_estado){
                                console.log("DDD:" +  element["total_geral_ant"], + element);
                                total_gripal_ant = element['total_gripal_ant'];
                                total_geral_ant = element["total_geral_ant"];
                                atualizaRetangulos (null, null, null,
                                    null,
                                    element['exc_evi_ivas'], 
                                    ((codigo_estado !== 53) ? "NaN" : element["sinal_evi_ivas"]), 
                                    "NaN", 
                                    element['populacao'], 
                                    element['total_gripal'], element["total_geral"], element["prob_gro_trend_ivas"], total_gripal_ant, total_geral_ant
                                );
                            }    

                            tempMakersMediaMovel.push(
                                criaTempMakersMediaMovelEstadual(element)
                                );
                        }
                    }
                });

            /*------------*/
            fetch('Dados/dados_municipios/' + path_dir + '/datas/dados_' + dt.replace(" 00:00:00", "") + '.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    //Limpa pontos anteriores
                    return response.json();
                })
                .then(function (myJson) {
                    let tempCidadesTabela = [];
                    setDados_Municipios_Por_Data(myJson);

                    console.log("passei aqui", myJson);
                    atualizaTotalSinais(!isAllSelect(estadoSelecionado), myJson);
                    if(myJson !== null /*&& !isAllSelect(estadoSelecionado)*/){
                        tempMakersMediaMovel = [];
                        for(let x = 0; x < myJson["municipios"].length; x++){
                            tempMakersMediaMovel.push(
                                criaTempMakersMediaMovelMunicipal(myJson["municipios"][x], myJson['data'], myJson['semana'])
                               );
                        }
                    }

                    if(shapeFileMunicipios.current !== null){
                        //plotaMapa(myJson["municipios"], "getDadosPorData");

                        criaPopups(tempMakersMediaMovel);
                    }  
                    /*if(!isAllSelect(estadoSelecionado)){
                        setMarkers(tempCidadesTabela);
                    }*/

                    setMarkers(/*!isAllSelect(estadoSelecionado) ? tempCidadesTabela :*/ tempMakersMediaMovel);
                    
                    
                    /*
                    setLoading(false);*/
                    
                    
                });
        }
    }

    const tabelaOrdenada = () => {
        var tMakers = [...markers];
     
        if(tMakers.length === 0){
            return [];
        }

        /*if(filtroTendenciaCrescimento != 0){
            if(filtroTendenciaCrescimento === 1){
                tMakers = tMakers.filter(item => item["prob_gro_trend_ivas"] !== null && (parseFloat(item["prob_gro_trend_ivas"]) * 100) <= 75.0);    
            } else if(filtroTendenciaCrescimento === 2){
                tMakers = tMakers.filter(item => item["prob_gro_trend_ivas"] !== null && (parseFloat(item["prob_gro_trend_ivas"]) * 100) > 75.0 && (parseFloat(item["prob_gro_trend_ivas"].toLowerCase()) * 100) <= 95.0);    
            } else if(filtroTendenciaCrescimento === 3){
                tMakers = tMakers.filter(item => item["prob_gro_trend_ivas"] !== null && (parseFloat(item["prob_gro_trend_ivas"]) * 100) > 95.0);      
            }
            
        }*/

        if (selectedVal_Prob_Gro_Trend) {
            const conditions = [];
        
            if (selectedVal_Prob_Gro_Trend.includes("≤75")) {
                conditions.push(item => item["prob_gro_trend_ivas"] !== null && parseFloat(item["prob_gro_trend_ivas"]) * 100 <= 75.0);
            }
        
            if (selectedVal_Prob_Gro_Trend.includes(">75 e ≤95")) {
                conditions.push(item => item["prob_gro_trend_ivas"] !== null && parseFloat(item["prob_gro_trend_ivas"]) * 100 > 75.0 && parseFloat(item["prob_gro_trend_ivas"]) * 100 <= 95.0);
            }
        
            if (selectedVal_Prob_Gro_Trend.includes(">95")) {
                conditions.push(item => item["prob_gro_trend_ivas"] !== null && parseFloat(item["prob_gro_trend_ivas"]) * 100 > 95.0);
            }
            
            //console.log(conditions, conditions.length);
            // Aplicar todas as condições selecionadas
            if(conditions.length <= 2){
                tMakers = tMakers.filter(item => conditions.some(condition => condition(item)));
            }
        }

        if (filtroDQIMapa != t("dashboard.todos")) {
            if(filtroDQIMapa.toLowerCase() === t("dashboard.apto").toLowerCase()){
                tMakers = tMakers.filter(item => item["dqi"].toLowerCase() === "apto");
            } else {
                tMakers = tMakers.filter(item => item["dqi"].toLowerCase() === "não apto");
            }
        }

        if (!isAllSelect(estadoSelecionado)) {
            tMakers = tMakers.filter(item => item["estado"].toLowerCase().startsWith(estadoSelecionado.toLowerCase()));
        }

        if (!isAllSelect(municipioSelecionado)) {
            tMakers = tMakers.filter(item => item["municipio"].toLowerCase().startsWith(municipioSelecionado.toLowerCase()));
        }        

        var campoOrdemLocal = campoOrdem;
        if (campoOrdemLocal === "diff_2w" || campoOrdemLocal === "perc_completude" ||campoOrdemLocal === "taxagripais_atendtotais" || campoOrdemLocal === "variacao_media_movel" || campoOrdemLocal === "limite_superior" || campoOrdemLocal === "excesso" || campoOrdemLocal === "evi" || campoOrdemLocal === "prob_gro_trend_ivas") {
            if (decrescente) {
                tMakers.sort(function (a, b) { return b[campoOrdemLocal] - a[campoOrdemLocal]; });
            } else {
                tMakers.sort(function (a, b) { return a[campoOrdemLocal] - b[campoOrdemLocal]; });
            }
        }
        else
            if (campoOrdemLocal === "municipio" || campoOrdemLocal === "estado") {
                if (decrescente) {
                    tMakers.sort((a, b) => (b[campoOrdemLocal].localeCompare(a[campoOrdemLocal])));
                } else {
                    tMakers.sort((a, b) => (a[campoOrdemLocal].localeCompare(b[campoOrdemLocal])));
                }
            } else {
                if (decrescente) {
                    tMakers.sort((a, b) => (a[campoOrdemLocal] > b[campoOrdemLocal]) ? -1 : 1);
                } else {
                    tMakers.sort((a, b) => (a[campoOrdemLocal] > b[campoOrdemLocal]) ? 1 : -1);
                }
            }

        if (posicao) {
            setTimeout(function () {
                window.scrollTo(0, posicao);
            }, 1);
        }

        //setLoading(false);
        return tMakers;
    }

    const getCodEstadoBySigla = (sigla) => {
        for(let i = 0; i < cod_estados.length; i++){
            if(cod_estados[i]["UF"] === sigla){
                return cod_estados[i]["COD"];
            }
        }
    }
    
    const getDadosGraficoTaxaMediaMovel = (dados_taxa_media_movel, fullScreen) => {
        //let legenda_tmp = getLabelDataRange(minIdxDate, maxIdxDate);
        return {
            legenda,
            datasets: [
                {
                    label: t("dashboard.graficos.titulo_variacao_mm"),
                    data: dados_taxa_media_movel,
                    borderColor: "#1cf58a"/*"#73f59b"*/,//borderColor4,//borderColor6,
                    backgroundColor: "#1cf58a"/*"#73f59b"*/,//backgroundColor4,//backgroundColor6,
                    borderWidth: fullScreen ? 2 : 1,
                    pointBorderWidth: fullScreen ? 2 : 1.5,
                    pointRadius: fullScreen ? 1.5 : 1.3,
                }
            ],

        };
    }

    const getDadosGraficoTotalAtendimentos = (dados_total_atendimentos) => {
        let datas = [];
        let dados = [];
        /*for (let index = 0; index < dados_total_atendimentos.length; index++) {
            const element = dados_total_atendimentos[index];
            datas.push(element["data"].toString());
            dados.push(element["total"]);            
        }*/

        return {
            /*legenda*/semanas,
            datasets: [
                {
                    label: t("dashboard.graficos.titulo_atendimento_totais"),
                    data: dados_total_atendimentos,
                    backgroundColor: corAtendimentosTotais,
                }
            ],
        };
    }

    const getDadosGraficoTotalAtendimentosTotaisEGripais = (dados_total_atendimentos, dados_total_atendimentos_gripais) => {
        let datas = [];
        let dados = [];
        /*for (let index = 0; index < dados_total_atendimentos.length; index++) {
            const element = dados_total_atendimentos[index];
            datas.push(element["data"].toString());
            dados.push(element["total"]);            
        }*/

        return {
            labels:legenda,
            datasets: [
                {
                    label: t("dashboard.graficos.titulo_atendimento_gripais"),
                    data: dados_total_atendimentos_gripais,
                    backgroundColor: corAtendimentosGripais,
                },
                {
                    label: t("dashboard.graficos.titulo_atendimento_totais"),
                    data: dados_total_atendimentos,
                    backgroundColor: corAtendimentosTotais,
                },
                
            ],
        };
    }

    const retornaCorLine = (index) => {
        const segmentColors = {
          1: 'rgba(255, 26, 104, 1)',
          2: 'rgba(255, 206, 86, 1)',
          3: 'rgba(75, 192, 192, 1)'
        };
        return segmentColors[index];
      };
    
      
    

    const getDadosGraficoTotalAtendimentosGripais = (dados_total_atendimentos_gripais, dados_prob_gro, fullScreen) => {
        //console.log(dados_prob_gro);
        return {
            /*legenda*/semanas,
            datasets: [
                {
                    cubicInterpolationMode: 'monotone',
                    label: "Probabilidade de crescimento",
                    data: dados_prob_gro, // Valores das barras
                    backgroundColor: ({chart, datasetIndex, dataIndex}) => {
                        const {ctx, data} = chart;

                        const dataset = data.datasets[datasetIndex]; // Obtém o conjunto de dados correspondente
                        const keys = Object.keys(dataset.data);

                        const value = dataset.data[keys[dataIndex]];

                        if(value <= 80){
                            return '#b9fd82';
                        } else if(value > 80){
                            return '#fe3a3a'
                        }    
                        
                    },
                    pointBorderColor: ({chart, datasetIndex, dataIndex}) => {
                        const {ctx, data} = chart;

                        const dataset = data.datasets[datasetIndex]; // Obtém o conjunto de dados correspondente
                        const keys = Object.keys(dataset.data);

                        const value = dataset.data[keys[dataIndex]];

                        if(value <= 80){
                            return '#b9fd82';
                        } else if(value > 0.80){
                            return '#fe3a3a'
                        }   
                    },
                    segment:{
                        borderColor: ({chart, datasetIndex, dataIndex, p0, p1, p0DataIndex, p1DataIndex}) => {
                            const {ctx, data} = chart;
    
                            const dataset = data.datasets[datasetIndex]; // Obtém o conjunto de dados correspondente
                            const keys = Object.keys(dataset.data);
    
                            const value1 = dataset.data[keys[p1DataIndex]];
                            const value2 = dataset.data[keys[p1DataIndex]];

                            const dataset2 = data.datasets[datasetIndex]; // Obtém o conjunto de dados correspondente
    
                            //console.log(dataset.data, p0, p1, p0DataIndex, p1DataIndex);
                            if(value2 <= 80){
                                return '#b9fd82';
                            } else if(value2 > 80){
                                return '#fe3a3a'
                            } 
                            
                            
                        }
                    },
                  
                    /*dadosProbGroTrend = Object.fromEntries(
                        dadosProbGroTrend.map(({ data, total }) => [data, total])
                    );
                    /*backgroundColor: dados_prob_gro.map(item => retornaCorLine(item)),*/
                    /*pointBorderColor: ((ctx) => retornaCorLine(ctx.raw.cor)),
                    segment:{
                        borderColor: ((ctx) => retornaCorLine(ctx.p0.raw.cor))
                    },*/
                    /*voltar aqui*/
                    type: 'line',
                    yAxisID: 'y'
                },
                {
                    label: t("dashboard.graficos.titulo_atendimento_gripais"),
                    data: dados_total_atendimentos_gripais,
                    backgroundColor: corAtendimentosGripais,
                    type: 'bar',
                    yAxisID: 'y1'
                },
            ],
                
        };
    }

    const getDadosGraficoRazaoAtendimentosGripais = (dados_media_movel, dados_razao, fullScreen) => {
        //let legenda_tmp = getLabelDataRange(minIdxDate, maxIdxDate);
        return {
            legenda,
            datasets: [
                {
                   /* yAxisID: 'y',*/
                    label: t("dashboard.graficos.legenda_mm.1"),
                    /*type: "line",*/
                    data: dados_media_movel,
                    borderColor: "#11d7b9",//corMediaMovel,
                    backgroundColor: "#11d7b9",//corMediaMovel,
                    borderWidth: fullScreen ? 2 : 1,
                    pointBorderWidth: fullScreen ? 2 : 1.5,
                    pointRadius: fullScreen ? 1.5 : 1.3,

                },
                {
                    /*yAxisID: 'y1',*/
                    label: t("dashboard.graficos.legenda_mm.2"),
                    /*type: "line",*/
                    data: dados_razao,
                    borderColor: "#9e47fa"/*corRazao*/,//borderColor6,borderColor4,
                    backgroundColor: "#9e47fa"/*corRazao*/,//backgroundColor6,//backgroundColor4,
                    borderWidth: fullScreen ? 2 : 1,
                    pointBorderWidth: fullScreen ? 2 : 1.5,
                    pointRadius: fullScreen ? 1.5 : 1.3,
                },
            ],
        };
    }

    const getDadosGraficoLimite = (dados_totao_atendimentos_gripais, dados_limite, fullScreen) => {
        return {
            legenda,
            datasets: [
                {
                    fill: true,
                    label: t("dashboard.graficos.titulo_atendimento_gripais"),
                    data: dados_totao_atendimentos_gripais,
                    borderColor: /*borderColor*/corAtendimentosGripais,
                    backgroundColor: /*backgroundColor*/corBackgroundAtendimentosGripais,
                    borderWidth: fullScreen ? 2 : 1,
                    pointBorderWidth: fullScreen ? 2 : 1.5,
                    pointRadius: fullScreen ? 1.5 : 1.3,
                },
                {
                    fill: true,
                    label: t("dashboard.graficos.titulo_limite"),
                    data: dados_limite,
                    borderColor: borderColor2,
                    backgroundColor: backgroundColor2,
                    borderWidth: fullScreen ? 2 : 1,
                    pointBorderWidth: fullScreen ? 2 : 1.5,
                    pointRadius: fullScreen ? 1.5 : 1.3,
                },
                
            ],

        };
    }

    const getDadosGraficoExcesso = (dados_excesso, fullScreen) => {
        return {
            legenda,
            datasets: [
                {
                    label: t("dashboard.graficos.titulo_excesso"),
                    data: dados_excesso,
                    borderColor: "#3b37d5"/*corExcessoAtendimentos*/,
                    backgroundColor: "#3b37d5"/*corExcessoAtendimentos*/,
                    borderWidth: fullScreen ? 2 : 1,
                    pointBorderWidth: fullScreen ? 2 : 1.5,
                    pointRadius: fullScreen ? 1.5 : 1.3,
                }
            ],

        };
    }

    const criaTempMakersMediaMovelEstadual = (element) => {
        return {
           "cod_ibge": element["cod_ibge"],
           "estado": getNomeEstadoByCodIbge(element["cod_ibge"]),
           "uf": element["uf"],
           "populacao": element["populacao"],
           "media_movel": element["media_movel_ivas"],
           "taxagripais_atendtotais": element["taxagripais_atendtotais"],
           "total_geral": element["total_geral"],
           "total_gripal": element["total_gripal"],
           "variacao_media_movel": (element["variacao_media_movel"] !== null && element["variacao_media_movel"] !== "") ? element["variacao_media_movel"].toFixed(2) : null,
           "data": dados_estados_por_data['data'],
           "semana": dados_estados_por_data['semana'], 
           "excesso": element['exc'],
           "limite_superior": element['mu'],
           //find para encontrar o excesso e limite superior
       };
   }
   
   const criaTempMakersMediaMovelMunicipal = (element, data, semana) =>{
       return {
           "cod_ibge": element["cod_ibge"],
           "municipio": element["municipio"],
           "cod_uf": element["cod_uf"],
           "estado": element["estado"],
           "uf": element["uf"],
           "populacao": element["populacao"],
           /*"cor_media_movel": element["cor_media_movel"],*/
           "media_movel": element["media_movel_ivas"],
           "taxagripais_atendtotais": element["taxagripais_atendtotais"],
           "total_geral": element["total_geral"],
           "total_gripal": element["total_gripal"],
           "variacao_media_movel": (element["variacao_media_movel"] !== null && element["variacao_media_movel"] !== "") ? element["variacao_media_movel"].toFixed(2) : null,
           "data": data,
           "semana": semana, //descomentei
           "excesso":indice.toLowerCase() === "ml" ? fixaCasasDecimais(element["exc_ml_ivas"], 2) :  indice.toLowerCase() === "ens" ? fixaCasasDecimais(element["exc_ens_ivas"], 2) : indice.toLowerCase() === "evi" ? fixaCasasDecimais(element["exc_evi_ivas"], 2) : indice.toLowerCase() === "ears" ? fixaCasasDecimais(element["exc_ears_ivas"], 2) : fixaCasasDecimais(element["exc_glm_ivas"], 2),
           "excesso_ambos_ivas": fixaCasasDecimais(element["exc_ambos_ivas"], 2),
           "excesso_evi_ivas": fixaCasasDecimais(element["exc_evi_ivas"], 2),
           "excesso_ears_ivas": fixaCasasDecimais(element["exc_ears_ivas"], 2), 
           "excesso_ens_ivas": fixaCasasDecimais(element["exc_ens_ivas"], 2), 
           "excesso_ml_ivas": fixaCasasDecimais(element["exc_ml_ivas"], 2), 
           "excesso_glm_ivas": fixaCasasDecimais(element["exc_glm_ivas"], 2), 
           "sinal_ears_consec_ivas": element["sinal_ears_consec_ivas"],
           "sinal_evi_consec_ivas": element["sinal_evi_consec_ivas"],
           "sinal_ambos_consec_ivas": element["sinal_ambos_consec_ivas"],
           "sinal_ens_consec_ivas": element["sinal_ens_consec_ivas"],
           "sinal_ml_consec_ivas": element["sinal_ml_consec_ivas"],
           "sinal_glm_consec_ivas": element["sinal_glm_consec_ivas"],
           "dqi": element['dqi'] == null || element['dqi'] == undefined ? "Sem Dados" : element['dqi'],
           "limite_superior_ivas": indice.toLowerCase() === "ml" ? fixaCasasDecimais(element["lim_esp_ml_ivas"], 2) : indice.toLowerCase() === "ens" ? element["lim_esp_ens_ivas"] : indice.toLowerCase() === "evi" ? element["lim_esp_evi_ivas"] : indice.toLowerCase() === "ears" ? element["lim_esp_ears_ivas"] : element["lim_esp_glm_ivas"],
           "limite_superior_evi_ivas" : fixaCasasDecimais(element["lim_esp_evi_ivas"], 2),
           "limite_superior_ears_ivas": fixaCasasDecimais(element["lim_esp_ears_ivas"], 2),
           "limite_superior_ambos_ivas": fixaCasasDecimais(element["lim_esp_ambos_ivas"], 2),
           "limite_superior_ens_ivas": fixaCasasDecimais(element["lim_esp_ens_ivas"], 2),
           "limite_superior_ml_ivas": fixaCasasDecimais(element["lim_esp_ml_ivas"], 2),
           "limite_superior_glm_ivas": fixaCasasDecimais(element["lim_esp_glm_ivas"], 2),
           "prob_gro_trend_ivas" : element['prob_gro_trend_ivas'],
           "perc_completude" : element['perc_completude'],
           "diff_2w" : element['diff_2w'] === undefined ? element['diff_2w'] : element['diff_2w']
           
           //find para encontrar o excesso e limite superior
       };	
   }

    const plotaMapa = (municipios, nomeFuncao) => {
        let total_atendimentos_ant = 0;
        let total_atendimentos_gripais_ant = 0;
        console.log("plotaMapa");
        atualizaTotalSinais(!isAllSelect(estadoSelecionado));

        Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
            var cod_ibge_tmp = getCodIbgeShapefile(shapeFileMunicipios.current, shape);
            let transparenciaFill = theme === "dark" ? "#8d8d8f" : "#e5e5e6"/*"rgba(255,255,255,0)"*/;
            for (let x = 0; x < municipios.length; x++) {
                var weight = weightNormal;
                if (cod_ibge_tmp === municipios[x]["cod_ibge"].toString()) {
                    
                    /*console.log(municipios[x]);*/
                    
                    var corEVI = municipios[x]["cor_ind"];
                    //var corEars = dados_municipios_por_data["municipios"][x][""];    
                    var alarmEars = (municipios[x]["sinal_ears_ivas"] != null && municipios[x]["sinal_ears_ivas"] == 1);
                    
                    var cor = transparenciaDefaultMapa;
                    var corContorno = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda;
    
                    let dqiApto = municipios[x]["dqi"] === "Apto" || municipios[x]["dqi"] === "Bom";
                    let filtroDQIApto = filtroDQIMapa.toLocaleLowerCase() === "apto" || filtroDQIMapa.toLocaleLowerCase() === "fit";

                    let exibeFiltrandoProb = true;

                    //ALTERAR AQUI
                    /*if(filtroTendenciaCrescimento > 0){
                        exibeFiltrandoProb = false;
                        if(municipios[x]["prob_gro_trend_ivas"] !== null){
                            let prob_gro_trend = parseFloat(municipios[x]["prob_gro_trend_ivas"]) * 100;
                        
                            if(filtroTendenciaCrescimento === 1 && prob_gro_trend <= 75.0){
                                exibeFiltrandoProb = true;
                            } else if(filtroTendenciaCrescimento === 2 && (prob_gro_trend > 75.0 && prob_gro_trend <= 95.0)){
                                exibeFiltrandoProb = true;
                            } else if(filtroTendenciaCrescimento === 3 && prob_gro_trend > 95.0){
                                exibeFiltrandoProb = true;
                            }
                        }
                        //console.log(municipios[x]["prob_gro_trend"], prob_gro_trend);
                    }*/

                    if (selectedVal_Prob_Gro_Trend.length > 0 && selectedVal_Prob_Gro_Trend.length < 3) {
                        exibeFiltrandoProb = false;
                        let prob_gro_trend = parseFloat(municipios[x]["prob_gro_trend_ivas"]) * 100;
                    
                        if (selectedVal_Prob_Gro_Trend.includes("≤75") && prob_gro_trend <= 75.0) {
                            exibeFiltrandoProb = true;
                        }
                    
                        if (selectedVal_Prob_Gro_Trend.includes(">75 e ≤95") && prob_gro_trend > 75.0 && prob_gro_trend <= 95.0) {
                            exibeFiltrandoProb = true;
                        }
                    
                        if (selectedVal_Prob_Gro_Trend.includes(">95") && prob_gro_trend > 95.0) {
                            exibeFiltrandoProb = true;
                        }
                    }

                    let exibeFiltrandoDQI = isAllSelect(filtroDQIMapa) || ((!dqiApto && !filtroDQIApto) || (dqiApto && filtroDQIApto));

                    //console.log(dqiApto, filtroDQIApto, exibeFiltrandoDQI, municipios[x]);
                    if(!dqiApto && filtroDQIApto && exibeFiltrandoProb){
                        weight = weightNormal;
                        cor = theme === "dark" ? corMapaNaoAptoDark : corMapaNaoApto;
                        corContorno = theme === "dark" ? corMapaNaoAptoBordaDark : corMapaNaoAptoBorda;
                    } 
                    else
                    if(exibeFiltrandoProb && exibeFiltrandoDQI){
                        weight = weightNormal;
                        if(indice.toLowerCase() === "ens"){
                            if(municipios[x]["sinal_ens_ivas"] === 0.0 || municipios[x]["sinal_ens_ivas"] === 0.5){
                                cor = exibeShapeFileVerde ? corMapa1 : transparenciaFill;
                                corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                            }  else if(municipios[x]["sinal_ens_ivas"] === 1.0){
                                cor = exibeShapeFileVermelho ? corMapa3 : transparenciaFill;
                                corContorno = corMapa3Borda;
                            }
                        } else
                        if(indice.toLowerCase() === "glm"){
                            if(municipios[x]["sinal_glm_ivas"] === 0.0 || municipios[x]["sinal_glm_ivas"] === 0.5){
                                cor = exibeShapeFileVerde ? corMapa1 : transparenciaFill;
                                corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                            } else if(municipios[x]["sinal_glm_ivas"] === 1.0){
                                cor = exibeShapeFileVermelho ? corMapa3 : transparenciaFill;
                                corContorno = corMapa3Borda;
                            }
                        } else 
                        if(indice.toLowerCase() === "ml"){
                            if(municipios[x]["sinal_ml_ivas"] === 0.0 || municipios[x]["sinal_ml_ivas"] === 0.5){
                                cor = exibeShapeFileVerde ? corMapa1 : transparenciaFill;
                                corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                            } else if(municipios[x]["sinal_ml_ivas"] === 1.0){
                                cor = exibeShapeFileVermelho ? corMapa3 : transparenciaFill;
                                corContorno = corMapa3Borda;
                            }
                        } else if(indice.toLowerCase() === "ambos"){
                            if(corEVI === "red" && alarmEars){
                                cor = exibeShapeFileVermelho ? corMapa3 : transparenciaFill;
                                corContorno = corMapa3Borda;
                                if(municipios[x]["sinal_evi_consec_ivas"] != null && municipios[x]["sinal_evi_consec_ivas"] > 1 && municipios[x]["sinal_ears_consec_ivas"] != null && municipios[x]["sinal_ears_consec_ivas"] > 1){
                                    cor = exibeShapeFileVermelho ? corMapa3_Escuro : transparenciaFill;
                                    corContorno = corMapa3_Escuro; 
                                }
                            }
                            else if(municipios[x]["sinal_evi_ivas"] === 0.5 && municipios[x]["sinal_ears_ivas"] === 0.5){
                                cor = exibeShapeFileAmarelo ? corMapa2 : transparenciaFill;
                                corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                            }
                            else {
                                cor = exibeShapeFileVerde ? corMapa1 : transparenciaFill;
                                corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                            }
                        } else if (indice.toLowerCase() === "evi") {
                            if(municipios[x]["sinal_evi_ivas"] === 0.0 || municipios[x]["sinal_evi_ivas"] === 0.5){
                                cor = exibeShapeFileVerde ? corMapa1 : transparenciaFill;
                                corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                            } else if(municipios[x]["sinal_evi_ivas"] === 1.0){
                                cor = exibeShapeFileVermelho ? corMapa3 : transparenciaFill;
                                corContorno = corMapa3Borda;
                            }
                        } else if(indice.toLowerCase() === "ears"){
                            if(municipios[x]["sinal_ears_ivas"] === "No data" || municipios[x]["sinal_ears_ivas"] === null){
                                cor = transparenciaDefaultMapa;
                                corContorno = corContorno;
                            } else
                            if(municipios[x]["sinal_ears_ivas"] === 1.0){
                                cor = exibeShapeFileVermelho ? corMapa3 : transparenciaFill;
                                corContorno = corMapa3Borda;
                            } else if(municipios[x]["sinal_ears_ivas"] === 0.5){
                                cor = exibeShapeFileAmarelo ? corMapa2 : transparenciaFill;
                                corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                            } else{
                                cor = exibeShapeFileVerde ? corMapa1 : transparenciaFill;
                                corContorno = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;  
                            }
                        }
                    }

                    if (cod_ibge === shapeFileMunicipios.current["_layers"][shape]["feature"]["properties"]["CD_MUN"]) {
                        corContorno = /*theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda*/"black";
                        weight = weightSelecao;
                        console.log("EEE:" +   municipios[x]["total_geral_ant"]);
                            
                        total_atendimentos_ant = municipios[x]["total_geral_ant"];
                        total_atendimentos_gripais_ant = municipios[x]["total_gripal_ant"];     
                        atualizaRetangulos (municipios[x]["exc_glm_ivas"],
                            municipios[x]["exc_ens_ivas"],
                            municipios[x]["exc_ml_ivas"],
                            municipios[x]["exc_ears_ivas"],
                            municipios[x]["exc_evi_ivas"], 
                            municipios[x]["evi"], 
                            municipios[x]["sinal_ears_ivas"], 
                            municipios[x]["populacao"], 
                            municipios[x]["total_gripal"], 
                            municipios[x]["total_geral"], 
                            municipios[x]["prob_gro_trend_ivas"], total_atendimentos_gripais_ant , total_atendimentos_ant);
                    }
    
                    if(corContorno === undefined){
                        corContorno = cor;
                    }
    
                    shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weight, color: corContorno, fillColor: cor, fillOpacity: opacidadeMunicipio});
    
                    break;  
                    
                }
            }                            
        });   

        setLoading(false); 
    }

    function carregaMunicipiosPorEstado(siglaEstado){
        if(!loading){
            setLoading(true);
        }

        if(siglaEstado !== ''){
            fetch('Dados/lista_municipios/lista_municipios_'+siglaEstado+'.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
            .then(function (response) {
                //Limpa pontos anteriores
                return response.json();
            })
            .then(function (myJson) {
                let listaTemp = [];
                let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
                for(let i = 0; i < myJson.length; i++){
                    listaTemp.push({
                        "cod_ibge": myJson[i]["COD_IBGE"],
                        "municipio": myJson[i]["MUNICIPIO"],
                        "latitude": myJson[i]["Y"],
                        "longitude": myJson[i]["X"]
                    });

                    cidades_labels_tmp.push({cod_ibge: myJson[i]["COD_IBGE"],  label: myJson[i]["MUNICIPIO"]});
                }

                

                setCidades_Labels(cidades_labels_tmp);

                listaTemp.sort((a, b) => a["municipio"].localeCompare(b["municipio"]));
                
                listaTemp.unshift({
                    "cod_ibge": 0,
                    "municipio": t("dashboard.todos")
                });

                setListaMunicipios(listaTemp); 
                
                plotaMarkerOTC(listaTemp);

                //revisar
                getDadosPorData(datas[idxDataSelecionada], language, true);
            });
        }
    }

    function zoomInicial(){     
        const { innerWidth: width, innerHeight: height } = window;
        if(map.current){
            if(width < 1300)
                map.current.setView([-13.338793, -45.206666], defaultZoom);
            else 
                map.current.setView(position, defaultZoom);
        }
    }

    function carregaDadosEstado(codigo_estado, siglaEstado){
        if(!loading){
            setLoading(true);
        }
        
        let dadosTemp = [];
        let dados2Temp = [];
        let dados3Temp = [];
        let dados4Temp = [];
        let dados_variacao_media_movelTemp = [];
        let dados_media_movelTemp = [];
        
        let dadosEVI = [];
        let dadosMU = [];
        let dadosEXC = [];

        let dadosProbGroTrend = [];

        let dados_o_numTemp = [];
        let dados_e_thrTemp = [];
        let dados_u_b_thrTemp = [];
        let dados_e_h_estimateTemp = [];
        let dados_p_e_h_estimateTemp = [];

        var acumulado_gripal = 0;

        if(codigo_estado !== 0){
           /* Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
                if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"] === estadoSelecionado){
                   map.current.fitBounds(shapeFileBrasil.current["_layers"][shape].getBounds());
                }                
            });*/

            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const element = shapeFileBrasil.current["_layers"][key];
                    if(element !== undefined && element["feature"]["properties"]["NM_UF"] === estadoSelecionado){
                        map.current.fitBounds(element.getBounds());
                        break;
                    } 
                }
            } 
            
            var tempMakersMediaMovel = [];
            for (let index = 0; index < dados_estados_por_data.current["estados"].length; index++) {
                const element = dados_estados_por_data.current["estados"][index];
                if(element['cod_ibge'].toString() === codigo_estado){
                    console.log("FFF:" +  element["total_gripal_ant"], element["total_geral_ant"]);
                    atualizaRetangulos (
                        element['exc_glm_ivas'],
                        element['exc_ens_ivas'],
                        element['exc_ml_ivas'],
                        element['exc_ears_ivas'],
                        element['exc_evi_ivas'], 
                        (codigo_estado !== 53) ? "NaN" : element["sinal_evi_ivas"], 
                        "NaN", 
                        element['populacao'], 
                        element['total_gripal'],
                        element["total_geral"], 
                        element["prob_gro_trend_ivas"], element['total_gripal_ant'] ,element["total_geral_ant"]);    
                }     

                if(isAllSelect(estadoSelecionado)){
                    tempMakersMediaMovel.push(
                        criaTempMakersMediaMovelEstadual(element)
                        );
                }
            }

            
            if(tempMakersMediaMovel != []){
                setMarkers(tempMakersMediaMovel);
                
            }
            
            /*fetch('Dados/dados_sinais_'+codigo_estado+'.json')
            .then(response => response.json())
            .then(data => {
                setDados_Sinais(data); // Atribui os dados JSON à variável global
                atualizaTotalSinais(true, data);
                
                //console.log(data);
            })
            .catch(error => {
                console.error('Erro ao carregar o arquivo JSON:', error);
            });*/

            fetch('Dados/dados_estados/estados/dados_' + codigo_estado + '.json'
                , {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                }
            )
                .then(function (response) {
                    //Limpa pontos anteriores
                    return response.json();
                })
                .then(function (myJson) {
                    dados_estado.current = myJson;

                    console.log("carregaDadosEstado");
                    atualizaTotalSinais(true);

                    for (let index = 0; index < dados_estado.current["atendimentos"].length; index++) {
                        const element = dados_estado.current["atendimentos"][index];

                        var obj = {};
                        obj['data'] = element["data"];
                        obj['total'] = element["total_geral"];
                        dadosTemp.push(obj);

                        var obj2 = {};
                        obj2['data'] = element["data"];
                        obj2['total'] = element["total_gripal"];
                        dados2Temp.push(obj2);

                        if(element["data"] <= maxDate && element["data"] >= minDate){
                            if(dadosGraficoAtendimentosGripaisMaxValue.current === null || dadosGraficoAtendimentosGripaisMaxValue.current < obj2['total']){
                                dadosGraficoAtendimentosGripaisMaxValue.current = obj2['total'];
                            }
                        } 

                        var obj3 = {};
                        obj3['data'] = element["data"];
                        obj3['total'] = (element["total_gripal"] / element["total_geral"]) * 100;
                        dados3Temp.push(obj3);

                        acumulado_gripal += element["total_gripal"];

                        var obj4 = {};
                        obj4['data'] = element["data"];
                        obj4['total'] = acumulado_gripal;
                        dados4Temp.push(obj4);

                        var obj5 = {};
                        obj5['data'] = element["data"];
                        obj5['total'] = element["media_movel_ivas"];
                        dados_media_movelTemp.push(obj5);

                        var obj6 = {};
                        obj6['data'] = element["data"];
                        obj6['total'] = element["variacao_media_movel"];
                        dados_variacao_media_movelTemp.push(obj6);

                        var obj8 = {};
                        obj8['data'] = element["data"];
                        if(indice.toLowerCase() === "ml"){
                            obj8['total'] = element["lim_esp_ml_ivas"];
                        } else if(indice.toLowerCase() === "ens"){
                            obj8['total'] = element["lim_esp_ens_ivas"];
                        } else if(indice.toLowerCase() === "evi"){
                            obj8['total'] = element["lim_esp_evi_ivas"];
                        } else if(indice.toLowerCase() === "ears"){
                            obj8['total'] = element["lim_esp_ears_ivas"];
                        } else if(indice.toLowerCase() === "glm"){
                            obj8['total'] = element["lim_esp_glm_ivas"];
                        }
                        //obj8['total'] = indice.toLowerCase() === "ml" ? element["lim_esp_ml_ivas"] : indice.toLowerCase() === "ens" ? element["lim_esp_ens_ivas"] : indice.toLowerCase() === "evi" ? element["lim_esp_evi_ivas"] : element["lim_esp_ears_ivas"];
                        dadosMU.push(obj8);

                        var obj9 = {};
                        obj9['data'] = element["data"];
                        if(indice.toLowerCase() === "ml"){
                            obj9['total'] = element["exc_ml_ivas"];
                        } else if(indice.toLowerCase() === "ens"){
                            obj9['total'] = element["exc_ens_ivas"];
                        } else if(indice.toLowerCase() === "evi"){
                            obj9['total'] = element["exc_evi_ivas"];
                        } else if(indice.toLowerCase() === "ears"){
                            obj9['total'] = element["exc_ears_ivas"];
                        } else if(indice.toLowerCase() === "glm"){
                            obj9['total'] = element["exc_glm_ivas"];
                        }
                        //obj9['total'] = indice.toLowerCase() === "ml" ? element["exc_ml_ivas"] : indice.toLowerCase() === "ens" ? element["exc_ens_ivas"] : indice.toLowerCase() === "evi" ? element["exc_evi_ivas"] : element["exc_ears_ivas"];
                        dadosEXC.push(obj9);

                        /*var obj10 = {};
                        obj10['data'] = element["data"];
                        obj10['total'] = element["prob_gro_trend_ivas"];
                        dadosProbGroTrend.push(obj10);*/
                    }

                    dadosTemp = Object.fromEntries(
                        dadosTemp.map(({ data, total }) => [data, total])
                    );
            
                    dados2Temp = Object.fromEntries(
                        dados2Temp.map(({ data, total }) => [data, total])
                    ); 
            
                    dados3Temp = Object.fromEntries(
                        dados3Temp.map(({ data, total }) => [data, total])
                    );
            
                    dados4Temp = Object.fromEntries(
                        dados4Temp.map(({ data, total }) => [data, total])
                    );

                    dados_media_movelTemp = Object.fromEntries(
                        dados_media_movelTemp.map(({ data, total }) => [data, total])
                    );

                    dadosMU = Object.fromEntries(
                        dadosMU.map(({ data, total }) => [data, total])
                    );

                    dadosEXC = Object.fromEntries(
                        dadosEXC.map(({ data, total }) => [data, total])
                    );

                    dados_variacao_media_movelTemp = Object.fromEntries(
                        dados_variacao_media_movelTemp.map(({ data, total }) => [data, total])
                    );

                    dadosProbGroTrend = Object.fromEntries(
                        dadosProbGroTrend.map(({ data, total }) => [data, total])
                    ); 

                    setDadosGraficoTotalAtendimentos(
                        getDadosGraficoTotalAtendimentos(dadosTemp)
                    ); 
            
                    setDadosTotalAtendimentosGripais(dados2Temp);

                    setDadosGraficoTotalAtendimentosGripais(
                        getDadosGraficoTotalAtendimentosGripais(dados2Temp, dadosProbGroTrend)
                    );

                    setDadosGraficoTotalAtendimentosFull(
                        getDadosGraficoTotalAtendimentos(dadosTemp, true)
                    );
            
                    setDadosGraficoTotalAtendimentosGripaisFull(
                        getDadosGraficoTotalAtendimentosGripais(dados2Temp, dadosProbGroTrend, true)
                    );

                    setDadosGraficoRazaoAtendimentosGripais(
                        //getDadosGraficoRazaoAtendimentosGripais(dadosEVI, dados3Temp)
                        getDadosGraficoRazaoAtendimentosGripais(dados_media_movelTemp, dados3Temp)
                    );

                    setDadosGraficoLimite(
                        getDadosGraficoLimite(dados2Temp, dadosMU)
                    )
        
                    setDadosGraficoLimiteFull(
                        getDadosGraficoLimite(dados2Temp, dadosMU, true)
                    ) 

                    setDadosGraficoExcesso(
                        getDadosGraficoExcesso(dadosEXC)
                    ) 

                    setDadosGraficoExcessoFull(
                        getDadosGraficoExcesso(dadosEXC, true)
                    )

                    setDadosGraficoTaxaMediaMovel(
                        getDadosGraficoTaxaMediaMovel(dados_variacao_media_movelTemp)
                    );
        
                    setDadosGraficoTaxaMediaMovelFull(
                        getDadosGraficoTaxaMediaMovel(dados_variacao_media_movelTemp)
                    );

                });

                carregaMunicipiosPorEstado(siglaEstado);
               
        } else {
            //Carregar dados Brasil

            if(isAllSelect(estadoSelecionado)){    
                /*fetch('Dados/dados_sinais_nacional.json')
                .then(response => response.json())
                .then(data => {
                    setDados_Sinais(data); // Atribui os dados JSON à variável global
                    atualizaTotalSinais(false, data);
                    
                    //console.log(data);
                })
                .catch(error => {
                    console.error('Erro ao carregar o arquivo JSON:', error);
                });*/

                let antendTotaisAnt = 0;
                let antendGripaisAnt = 0;
                fetch('Dados/dados_pais/dados_brasil.json'
                    , {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    }
                )
                .then(function (response) {
                    //Limpa pontos anteriores
                    return response.json();
                })
                .then(function (myJson) {
                    if(myJson !== null){
                        setDados_Pais(myJson);
                        console.log("carregaDadosEstado");
                        atualizaTotalSinais(false);
                        myJson.forEach(function (dado) {
                                
                                if(new Date(dataSelecionada).getTime() === new Date(dado["data"]).getTime()){
                                    console.log("AAA:" +  dado["total_geral_ant"]);
                                    
                                    antendTotaisAnt = dado["total_geral_ant"];
                                    antendGripaisAnt = dado["total_gripal_ant"];
                                    atualizaRetangulos (dado["exc_glm_ivas"],
                                                        dado["exc_ens_ivas"],
                                                        dado["exc_ml_ivas"],
                                                        dado["exc_ears_ivas"],
                                                        dado["exc_evi_ivas"], 
                                                        dado["sinal_evi_ivas"], 
                                                        "NaN", 
                                                        dado["populacao"], 
                                                        dado["total_gripal"], 
                                                        dado["total_geral"], 
                                                        dado["prob_gro_trend_ivas"], antendGripaisAnt, antendTotaisAnt);

                                    /*setNumAtendimentosGripais(dado["total_gripal"]);
                                    setEviRetangulo(fixaCasasDecimais(dado["evi"], 2));
                                    setTxCrescimento(fixaCasasDecimais(dado["variacao_media_movel"], 2));
                                    setExcesso(dado["exc"]);
                                    setPopulacao(dado["populacao"]);*/
                                    
                                }

            
                                var obj = {};
                                obj['data'] = dado["data"];
                                obj['total'] = dado["total_geral"];
                                dadosTemp.push(obj);
            
                                var obj2 = {};
                                obj2['data'] = dado["data"];
                                obj2['total'] = dado["total_gripal"];
                                dados2Temp.push(obj2);
            
                                var obj3 = {};
                                obj3['data'] = dado["data"];
                                obj3['total'] = (dado["total_gripal"] / dado["total_geral"]) * 100;
                                dados3Temp.push(obj3);
            
                                acumulado_gripal += dado["total_gripal"];
                                var obj4 = {};
                                obj4['data'] = dado["data"];
                                obj4['total'] = acumulado_gripal;
                                dados4Temp.push(obj4);
            
                                var obj5 = {};
                                obj5['data'] = dado["data"];
                                obj5['total'] = dado["media_movel_ivas"];
                                dados_media_movelTemp.push(obj5);
            
                                var obj6 = {};
                                obj6['data'] = dado["data"];
                                obj6['total'] = dado["variacao_media_movel"];
                                dados_variacao_media_movelTemp.push(obj6);
            
                                var obj13 = {};
                                obj13['data'] = dado["data"];
                                if(indice.toLowerCase() === "ml"){
                                    obj13['total'] = dado["lim_esp_ml_ivas"];
                                } else if(indice.toLowerCase() === "ens"){
                                    obj13['total'] = dado["lim_esp_ens_ivas"];
                                } else if(indice.toLowerCase() === "evi"){
                                    obj13['total'] = dado["lim_esp_evi_ivas"];
                                } else if(indice.toLowerCase() === "ears"){
                                    obj13['total'] = dado["lim_esp_ears_ivas"];
                                } else if(indice.toLowerCase() === "glm"){
                                    obj13['total'] = dado["lim_esp_glm_ivas"];
                                }
                                //obj13['total'] = indice.toLowerCase() === "ml" ? dado["lim_esp_ml_ivas"] : indice.toLowerCase() === "ens" ? dado["lim_esp_ens_ivas"] : indice.toLowerCase() === "evi" ? dado["lim_esp_evi_ivas"] : dado["lim_esp_ears_ivas"];
                                dadosMU.push(obj13);
            
                                var obj14 = {};
                                obj14['data'] = dado["data"];
                                if(indice.toLowerCase() === "ml"){
                                    obj14['total'] = dado["exc_ml_ivas"];
                                } else if(indice.toLowerCase() === "ens"){
                                    obj14['total'] = dado["exc_ens_ivas"];
                                } else if(indice.toLowerCase() === "evi"){
                                    obj14['total'] = dado["exc_evi_ivas"];
                                } else if(indice.toLowerCase() === "ears"){
                                    obj14['total'] = dado["exc_ears_ivas"];
                                } else if(indice.toLowerCase() === "glm"){
                                    obj14['total'] = dado["exc_glm_ivas"];
                                }
                                //obj14['total'] = indice.toLowerCase() === "ml" ? dado["exc_ml_ivas"] : indice.toLowerCase() === "ens" ? dado["exc_ens_ivas"] : indice.toLowerCase() === "evi" ? dado["exc_evi_ivas"] : dado["exc_ears_ivas"];
                                dadosEXC.push(obj14);

                                /*var obj15 = {};
                                obj15['data'] = dado["data"];
                                obj15['total'] = dado["prob_gro_trend_ivas"];
                                dadosProbGroTrend.push(obj15);*/
                        });
            
                        dadosTemp = Object.fromEntries(
                            dadosTemp.map(({ data, total }) => [data, total])
                        ); 
                
                        dados2Temp = Object.fromEntries(
                            dados2Temp.map(({ data, total }) => [data, total])
                        );
                
                        dados3Temp = Object.fromEntries(
                            dados3Temp.map(({ data, total }) => [data, total])
                        );
                
                        dados4Temp = Object.fromEntries(
                            dados4Temp.map(({ data, total }) => [data, total])
                        );
                
                        dados_media_movelTemp = Object.fromEntries(
                            dados_media_movelTemp.map(({ data, total }) => [data, total])
                        );
                
                        dados_variacao_media_movelTemp = Object.fromEntries(
                            dados_variacao_media_movelTemp.map(({ data, total }) => [data, total])
                        );
            
                        dadosEVI = Object.fromEntries(
                            dadosEVI.map(({ data, total }) => [data, total])
                        );
            
                        dadosMU = Object.fromEntries(
                            dadosMU.map(({ data, total }) => [data, total])
                        );
            
                        dadosEXC = Object.fromEntries(
                            dadosEXC.map(({ data, total }) => [data, total])
                        );

                        dadosProbGroTrend = Object.fromEntries(
                            dadosProbGroTrend.map(({ data, total }) => [data, total])
                        );
                
                        setDadosGraficoTotalAtendimentos(
                            getDadosGraficoTotalAtendimentos(dadosTemp)
                        );
            
                        setDadosGraficoTotalAtendimentosFull(
                            getDadosGraficoTotalAtendimentos(dadosTemp)
                        );
                
                        setDadosTotalAtendimentosGripais(dados2Temp);

                        setDadosGraficoTotalAtendimentosGripais(
                            getDadosGraficoTotalAtendimentosGripais(dados2Temp, dadosProbGroTrend)
                        );
            
                        setDadosGraficoTotalAtendimentosGripaisFull(
                            getDadosGraficoTotalAtendimentosGripais(dados2Temp, dadosProbGroTrend, true)
                        );
                
                        setDadosGraficoRazaoAtendimentosGripais(
                            getDadosGraficoRazaoAtendimentosGripais(dados_media_movelTemp, dados3Temp)
                        );
            
                        setDadosGraficoRazaoAtendimentosGripaisFull(
                            getDadosGraficoRazaoAtendimentosGripais(dados_media_movelTemp, dados3Temp)
                        );
                
                        setDadosGraficoTaxaMediaMovel(
                            getDadosGraficoTaxaMediaMovel(dados_variacao_media_movelTemp)
                        );
            
                        setDadosGraficoTaxaMediaMovelFull(
                            getDadosGraficoTaxaMediaMovel(dados_variacao_media_movelTemp)
                        );
                
                        setDadosGraficoLimite(
                            getDadosGraficoLimite(dados2Temp, dadosMU)
                        )

                        setDadosGraficoLimiteFull(
                            getDadosGraficoLimite(dados2Temp, dadosMU, true)
                        )
            
                        setDadosGraficoExcesso(
                            getDadosGraficoExcesso(dadosEXC)
                        ) 

                        setDadosGraficoExcessoFull(
                            getDadosGraficoExcesso(dadosEXC, true)
                        )

                        setDados_Pais(myJson);

                    }
                    setTimeout(function () {  
                        setLoading(false);
                    }, 3000);
                }); 

                var tempMakersMediaMovel = [];
                if(dados_municipios_por_data != null){
                    console.log("carregaDadosEstado1");
                    atualizaTotalSinais(false);
                    for (let index = 0; index < dados_municipios_por_data["municipios"].length; index++) {
                        const element = dados_municipios_por_data["municipios"][index];  
        
                        tempMakersMediaMovel.push(
                            criaTempMakersMediaMovelMunicipal(element, dados_municipios_por_data['data'], dados_municipios_por_data['semana'])
                            );
                    }
                    setMarkers(tempMakersMediaMovel);
                    
                    
                }

                zoomInicial();
            }
        }
        
    }

    function plotaMarkerOTC(listaMunicipios){
        if(markersLayer !== null){
            removeMarkers();
        }
                
        if(listaMunicipios.length > 0){   
            if(dados_municipios_por_data != null){
                let codigo_estado = getCodEstadoByName(estadoSelecionado);

                let exibeOTCComAviso = selectedOTC.includes(t("dashboard.aviso"));
                let exibeOTCSemAviso = false;//selectedOTC.includes(t("dashboard.sem_aviso"));

                if(!exibeOTCComAviso && !exibeOTCSemAviso){
                    return;
                }
                for (let index = 0; index < dados_municipios_por_data["municipios"].length; index++) {
                    const element = dados_municipios_por_data["municipios"][index];  
                    if(element['cod_uf'].toString() === codigo_estado){
                        for(let i=0; i < listaMunicipios.length; i++){
                            if(element['sinal_otc_ivas'] !== null && listaMunicipios[i]["cod_ibge"] === element["cod_ibge"].toString() && listaMunicipios[i]["latitude"] != undefined && listaMunicipios[i]["longitude"] != undefined){
                                let icons = null;
                                if(exibeOTCSemAviso && element['sinal_otc_ivas'] === 0.0){
                                    icons = createIcon(iconVerdeUrl);
                                } else if(exibeOTCComAviso && element['sinal_otc_ivas'] === 1.0){
                                    icons = createIcon(iconVermelhoUrl);
                                }
                                if(icons !== null){
                                    addMarker([listaMunicipios[i]["latitude"], listaMunicipios[i]["longitude"]], icons);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    function criarLegenda(min, max, variavel, linguagem) {
        if (map.current === null)
            return;
        if (legend.current !== null) {
            map.current.removeControl(legend.current );
            legend.current  = null;
        }
        legend.current  = L.control({ position: "bottomleft" });

        if(variavel === ""){
            return;
        }

        if(variavel === "ind"){
            legend.current.onAdd = function (map) {
                var div = null;
                div = L.DomUtil.create("div", "legend_");
                //div.innerHTML += '<table><tr><th  bgcolor='+corMapa1+'>&nbsp;&nbsp;&nbsp;</th><th  bgcolor='+corMapa2+'>&nbsp;&nbsp;&nbsp;</th><th bgcolor='+corMapa3+'>&nbsp;&nbsp;&nbsp;</th></tr><tr><td style="text-align:left">0</td><td style="text-align:center">0.5</td><td style="text-align:right">1</td></tr>';
                //div.innerHTML += '<table><tr><th  bgcolor='+corMapa1+'>&nbsp;&nbsp;&nbsp;</th><th bgcolor='+corMapa3+'>&nbsp;&nbsp;&nbsp;</th></tr><tr><td style="text-align:left">0</td><td style="text-align:right">1</td></tr>';
                
                //div.innerHTML += '</table>';

                // Adicionando linhas para cada cor e descrição 
                var descricaoIndice = "";
                if(indice.toLowerCase() === "ml"){
                    descricaoIndice = t("dashboard.table.ml");
                } else if(indice.toLowerCase() === "ens"){
                    descricaoIndice = t("dashboard.table.ens");
                } else if(indice.toLowerCase() === "evi") {
                    descricaoIndice = t("dashboard.evi_simples");
                } else if(indice.toLowerCase() === "glm") {
                    descricaoIndice = t("dashboard.table.glm");
                } else {
                    descricaoIndice = t("dashboard.ears");
                }

                let corNaoApto = theme === "dark" ? corMapaNaoAptoDark : corMapaNaoApto;
                let corNoData = theme === "dark" ? corMapaNoDataDark : corMapaNoData;

                div.innerHTML += '<center><h6 class="titulo-legend">' + descricaoIndice + '</h6></center>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa3+'"></div>'+t("dashboard.aviso") + '</div>';
                /*div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: lightcoral;"></div>'+t("dashboard.aviso_consecutivo")+'</div>';*/
                /*div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa2+'"></div>'+t("dashboard.alerta")+'</div>';*/
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa1+'"></div>'+t("dashboard.sem_aviso")+'</div>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+ corNaoApto +'"></div>'+t("dashboard.nao_apto")+'</div>';
               /* div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+ corNoData +'"></div>'+t("dashboard.no_data")+'</div>';*/

                div.innerHTML += '</div>';
                return div;
            };            
        } else if(variavel === "exc"){
            legend.current.onAdd = function (map) {
                /*var div = null;
                if (linguagem === "pt")
                    div = L.DomUtil.create("div", "legend2");
                else
                    div = L.DomUtil.create("div", "legend");
                div.innerHTML += '<div class="lgd">';
                div.innerHTML += '<table><tr><th  bgcolor='+corMapa1Excesso+'>&nbsp;&nbsp;&nbsp;</th><th  bgcolor='+corMapa2Excesso+'>&nbsp;&nbsp;&nbsp;</th><th bgcolor='+corMapa3Excesso+'>&nbsp;&nbsp;&nbsp;</th><th bgcolor='+corMapa4Excesso+'>&nbsp;&nbsp;&nbsp;</th></tr><tr><td style="text-align:left">25%</td><td style="text-align:center">50%</td><td style="text-align:right">75%</td><td style="text-align:right">100%</td></tr>';
                div.innerHTML += '</table>';
                div.innerHTML += '</div>';
                return div;*/
                var div = null;
                div = L.DomUtil.create("div", "legend_");
                //div.innerHTML += '<table><tr><th  bgcolor='+corMapa1+'>&nbsp;&nbsp;&nbsp;</th><th  bgcolor='+corMapa2+'>&nbsp;&nbsp;&nbsp;</th><th bgcolor='+corMapa3+'>&nbsp;&nbsp;&nbsp;</th></tr><tr><td style="text-align:left">0</td><td style="text-align:center">0.5</td><td style="text-align:right">1</td></tr>';
                //div.innerHTML += '<table><tr><th  bgcolor='+corMapa1+'>&nbsp;&nbsp;&nbsp;</th><th bgcolor='+corMapa3+'>&nbsp;&nbsp;&nbsp;</th></tr><tr><td style="text-align:left">0</td><td style="text-align:right">1</td></tr>';
                
                //div.innerHTML += '</table>';

                // Adicionando linhas para cada cor e descrição 

                var descricaoIndice = "";
                if(indice.toLowerCase() === "ml"){
                    descricaoIndice = t("dashboard.table.ml");
                } else if(indice.toLowerCase() === "ens"){
                    descricaoIndice = t("dashboard.table.ens");
                } else if(indice.toLowerCase() === "evi") {
                    descricaoIndice = t("dashboard.evi_simples");
                } else if(indice.toLowerCase() === "glm") {
                    descricaoIndice = t("dashboard.table.glm");
                } else {
                    descricaoIndice = t("dashboard.ears");
                }

                div.innerHTML += '<center><h6 class="titulo-legend">'+t('dashboard.probabilidade_crescimento')+'</h6></center>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa1Excesso+'"></div> <= 95%</div>';
                div.innerHTML += '<div class="legend-item"><div class="square" style="background-color: '+corMapa4Excesso+'"></div>> 95%</div>';

                div.innerHTML += '</div>';
                return div;
            };
        }

        legend.current.addTo(map.current);
    }

    const atualizaRangeGraficos = (valor, tipo, validacao) => {
        if(validacao){
            if(tipo === "max"){
                if(parseInt(valor) <= (parseInt(minIdxDate) + 1)){

                    return;
                }
            } else {
                if(parseInt(valor) >= (parseInt(maxIdxDate) - 1) ){
                    valor = parseInt(maxIdxDate) - 2;
                    //return;
                }
            } 
        }

        /*let maior = tipo === "max" ? parseInt(valor) : maxIdxDate;
        let menor = tipo === "min" ? parseInt(valor) : minIdxDate;

        var minPercent = getPercent(parseInt(menor));
        var maxPercent = getPercent(parseInt(maior));*/

        if (tipo === "max") {
            setMaxDate(datas[valor]);
            setFimSemanaSelecionada(fimSemanas[valor]);
            setMaxIdxDate(valor);
            setValueRangeData([minIdxDate,valor]);
/*
            if(minPercent < 5)
                minPercent = 5; */

            setIdxDataSelecionada(valor);
            setDataSelecionada(datas[valor]);
            setSemanaSelecionada(semanas[valor]);
            
            setInicioSemanaSelecionadaRange(semanas[minIdxDate]);
            setFimSemanaSelecionadaRange(semanas[valor]);

            setMinDateRangeInicial(inicioSemanas[minIdxDate]);
            setMinDateRangeFinal(fimSemanas[minIdxDate]);
            setMaxDateRangeInicial(inicioSemanas[valor]);
            setMaxDateRangeFinal(fimSemanas[valor]);

            /*setSizeRange({width: (maxPercent - minPercent) + "%", left: sizeRange.left});*/
            ////filtrarMunicipio(municipioSelecionado, false, props.data,"",datas[minIdxDate],datas[valor]);
            
        } else {
            setMinDate(datas[valor]);
            setMinDateRangeInicial(inicioSemanas[valor]);
            setMinDateRangeFinal(fimSemanas[valor]);
            setMaxDateRangeInicial(inicioSemanas[maxIdxDate]);
            setMaxDateRangeFinal(fimSemanas[maxIdxDate]);

            setInicioSemanaSelecionadaRange(semanas[valor]);
            setFimSemanaSelecionadaRange(semanas[maxIdxDate]);
            setMinIdxDate(valor);

            /*let idxDataInicio = getIdxDataInicio(datas);
            setMinDate(datas[idxDataInicio]);
            setMinIdxDate(idxDataInicio);*/
            
            setValueRangeData([valor,maxIdxDate]);

            /*
            if(maxPercent > 85)
                maxPercent = 85;*/

            /*setSizeRange({width: (maxPercent - minPercent) + "%", left: minPercent+ "%"});*/
            ////filtrarMunicipio(municipioSelecionado, false,props.data,"",datas[valor],datas[maxIdxDate]);
        }
    }

    const limpaFiltros = () => {
        setIndice("ENS"); 
        setFiltroDQIMapa(t("dashboard.apto")); 
        setEstadoSelecionado(t("dashboard.todos")); 
        setMunicipioSelecionado(t("dashboard.todos"));
    }

    const limpaShapeFile = (shapeFile, estadoLimpar, municipioLimpar, limparSelecao) => {
        if(shapeFile.current && shapeFile.current["_layers"])
        {
            Object.keys(shapeFile.current["_layers"]).forEach((shape) => {
                shapeFile.current["_layers"][shape].setStyle({ weight: 1, color: transparenciaDefaultMapa, fillColor: transparenciaDefaultMapa, fillOpacity: 0.5 });   
            });
        } else if(shapeFile && shapeFile["_layers"] && estadoLimpar != undefined && municipioLimpar != undefined){

            let fillOpacity = transparencia.current;
            Object.keys(shapeFile["_layers"]).forEach((shape) => {
                let corBordaAnt = shapeFile["_layers"][shape]["options"]["fillColor"];
                let corBordaAtual = shapeFile["_layers"][shape]["options"]["color"];
                let weightLocal = weightNormal;
                if(!limparSelecao && (corBordaAtual === "white" || corBordaAtual === "#757575")){
                    corBordaAnt = "black";
                    weightLocal = weightSelecao;
                    fillOpacity = 1.0;
                }
                else
                if(corBordaAnt === corMapa1){
                    corBordaAnt = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                } else if(corBordaAnt === corMapa3){
                    corBordaAnt = corMapa3_Escuro;
                } else {
                    corBordaAnt = transparenciaDefaultMapa;  
                }

                shapeFile["_layers"][shape].setStyle({ weight: weightLocal, color: corBordaAnt, fillOpacity: fillOpacity });
            });
        }
    }

    //states
    const [loading, setLoading] = useState(true);
    const [cod_ibge, setCod_Ibge] = useState(t("dashboard.todos"));
    const [municipioSelecionado, setMunicipioSelecionado] = useState(t("dashboard.todos"));
    const [estadoSelecionado, setEstadoSelecionado] = useState(t("dashboard.todos"));
    const [listaMunicipios, setListaMunicipios] = useState(getCidades());
    const [listaEstados, setListaEstados] = useState(getEstados());
    const [listaSinaisAviso, setlistaSinaisAviso] = useState(
        [
            {"sinal": "EVI", "nome":  t("dashboard.evi_simples")},
            {"sinal": "Ears", "nome": t("dashboard.ears")},
            {"sinal": "ML", "nome": "ML"},
            {"sinal": "GLM", "nome": "GLM"},
            {"sinal": "ENS", "nome": "ENSEMBLE"},
        ]);
    const [layerAtiva, setLayerAtiva] = useState("");
    const [nomeLayerAtiva, setNomeLayerAtiva] = useState(t("dashboard.excesso"));
    const [erroGeral,setErroGeral] = useState("");

    //states cores exibidas
    const [exibeShapeFileVerde, setExibeShapeFileVerde] = useState(true);
    const [exibeShapeFileAmarelo, setExibeShapeFileAmarelo] = useState(true);
    const [exibeShapeFileVermelho, setExibeShapeFileVermelho] = useState(true);
    const [exibeShapeFileCinza, setExibeShapeFileCinza] = useState(true);

    //refs
    const semanas_epidemiologicas = useRef(null);
    const iniciou = useRef(false);
    const [maxRazao, setMaxRazao] = useState(100);
    const carregandoEstado = useRef(false);
    const dados_estado = useRef(null);

    //refs shapefiles
    const shapeFileBrasil = useRef(null);
    const shapeFileBrasilExcesso = useRef(null);
    const shapeFileMunicipios = useRef(null);

    //refs mapa
    const layerTile = useRef(null);
    const layerControl = useRef(null);
    let map = useRef(null);

    const [markersLayer, setMarkersLayer] = useState(null);

    const selecionaMunicipio = useRef(null);

    useEffect(() => {
        if(loading){
            setTimeout(() => {
                setLoading(false);
            }, 5000);
        }
    }, [loading]);

    /*Variáveis Estruturais*/
    /*Variáveis de Data*/
    const [semanaSelecionada, setSemanaSelecionada] = useState(0);
    const [inicioSemanaSelecionada, setInicioSemanaSelecionada] = useState(null);
    const [fimSemanaSelecionada, setFimSemanaSelecionada] = useState(null);
    const [inicioSemanaSelecionadaRange, setInicioSemanaSelecionadaRange] = useState(null);
    const [fimSemanaSelecionadaRange, setFimSemanaSelecionadaRange] = useState(null);
    const [semanas, setSemanas] = useState([]);
    const [inicioSemanas, setInicioSemanas] = useState([]);
    const [fimSemanas, setFimSemanas] = useState([]);
    const [idxDataSelecionada, setIdxDataSelecionada] = useState(0);
    const [dataSelecionada, setDataSelecionada] = useState(0);
    const [minDate, setMinDate] = useState(null);
    const [minDateRangeInicial, setMinDateRangeInicial] = useState(null);
    const [minDateRangeFinal, setMinDateRangeFinal] = useState(null);
    const [minIdxDate, setMinIdxDate] = useState(0);
    const [maxDate, setMaxDate] = useState(null);
    const [maxDateRangeInicial, setMaxDateRangeInicial] = useState(null);
    const [maxDateRangeFinal, setMaxDateRangeFinal] = useState(null);
    const [maxIdxDate, setMaxIdxDate] = useState(0);
    const [datas, setDatas] = useState([]);
    const [maxLengthData, setMaxLengthData] = useState(0);
    /*Fim Variáveis de Data*/
    /*Fim Variáveis Estruturais*/

    /*Variáveis Mapa*/
    const position = [-15.0, -54.27390005057997];
    const defaultZoom = 3.00;
    const zoomSnap_ = 0.25;
    const wheelPxPerZoomLevel_ = 120;
    const [maiorTaxa, setMaiorTaxa] = useState(0);
    const [menorTaxa, setMenorTaxa] = useState(0);
    const cinzaDefaultMapa = useMemo(() => {
        return "rgba(221, 220, 223, 1)";
    }, []);
    const cinzaDefaultMapaWhite = useMemo(() => {
        return "rgba(200, 200, 200, 1)";
    }, []);

    const cinzaDefaultMapaBrasilWhite = useMemo(() => {
        return "rgba(185, 185, 185, 1)";
    }, []);
    const cinzaDefaultMapaDark = useMemo(() => {
        return "#272727";
    }, []);
    const cinzaDefaultSelecaoMapa = useMemo(() => {
        return "rgba(221, 220, 223, 1)";
    }, []);
    const cinzaDefaultSelecaoMapaWhite = useMemo(() => {
        return "rgba(137, 137, 137, 1)";
    }, []);
    
    const cinzaDefaultMapaBorda = useMemo(() => {
        return "#C1c3c3";//"rgba(221, 220, 223, 0.3)";
    }, []);

    var weightNormal = theme === "dark" ? 0.5 : 0.3;
    var weightBrasil = 1.5;

    var weightSelecao = 2.5; 

    const transparencia = useRef(0.5); 
    var geojsonBrasil = (require('./Dados/shapefile/BR_UF_2022.json'));
    var geojsonMunicipios = (require('./Dados/shapefile/BR_Municipios_2022.json'));

    const transparenciaDefaultMapa = useMemo(() => {
        return "rgba(255, 255, 255, 0)";
    }, []);
    /*Fim Variáveis Mapa*/

    /*Variáveis Gráficos*/
    const isResponsive = true;
    const maintainAspectRatio = true;

    const leftGrafico = 0;
    const rightGrafico = 0;
    const topGrafico = 0;
    const bottomGrafico = 25;
    /*Fim Variáveis Gráficos*/

    /*Variáveis Retangulos*/
    const [populacao, setPopulacao] = useState(0);
    const [numAtendimentosGripais, setNumAtendimentosGripais] = useState(0);
    const [numAtendimentosGripaisAnterior, setNumAtendimentosGripaisAnterior] = useState(0);
    const [excesso, setExcesso] = useState(0);
    const [eviRetangulo, setEviRetangulo] = useState(0.0);
    const [earsRetangulo, setEarsRetangulo] = useState(0.0);
    const [atendimentoTotaisRetangulo, setAtendimentosTotaisRetangulo] = useState(0.0);
    const [atendimentoTotaisRetanguloAnterior, setAtendimentosTotaisRetanguloAnterior] = useState(0.0);
    const [probabilidadeDeCrescimentoRetangulo, setProbabilidadeDeCrescimentoRetangulo] = useState(0.0);
    const [txCrescimento, setTxCrescimento] = useState(0.0);   
    /*Fim Variáveis Retangulos*/

    /*Variáveis Popup Gráfico*/
    const [modal3Show, setModal3Show] = useState(false);
    /*Fim Variáveis Popup Gráfico*/


    /*const [filtroDQITabela, setFiltroDQITabela] = useState(t("dashboard.apto"));*/
    const [filtroDQIMapa, setFiltroDQIMapa] = useState(t("dashboard.apto"));
    const [filtroTendenciaCrescimento, setFiltroTendenciaCrescimento] = useState(0);

    /*Variáveis Gráficos*/
    const corExcessoAtendimentos = useMemo(() => {
        return '#7682d6';
    }, []);
    const corAtendimentosTotais = useMemo(() => {
        return 'rgba(255, 99, 132, 1.0)';
    }, []); 
    const corAtendimentosGripais = useMemo(() => {
        return '#2fb4ef';
    }, []);   
    const corMediaMovel = useMemo(() => {
        return '#81d6cf';
    }, []); 
    const backgroundColor4 = useMemo(() => { 
        return '#90ecaf';
    }, []); 

    const [dadosGraficoRazaoAtendimentosGripais, setDadosGraficoRazaoAtendimentosGripais] = useState(getDadosGraficoRazaoAtendimentosGripais([], []));
    const [dadosGraficoTaxaMediaMovel, setDadosGraficoTaxaMediaMovel] = useState(getDadosGraficoTaxaMediaMovel([]));
    const [dadosGraficoTotalAtendimentosGripais, setDadosGraficoTotalAtendimentosGripais] = useState(getDadosGraficoTotalAtendimentosGripais([]));
    const [dadosGraficoTotalAtendimentos, setDadosGraficoTotalAtendimentos] = useState(getDadosGraficoTotalAtendimentos([]));
    const [dadosGraficoTotalAtendimentosTotaisEGripais, setDadosGraficoTotalAtendimentosTotaisEGripais] = useState(getDadosGraficoTotalAtendimentosTotaisEGripais([],[]));
    const [dadosGraficoExcesso, setDadosGraficoExcesso] = useState(getDadosGraficoExcesso([]));
    const [dadosGraficoLimite, setDadosGraficoLimite] = useState(getDadosGraficoLimite([], []));
    
    const [dadosGraficoRazaoAtendimentosGripaisFull, setDadosGraficoRazaoAtendimentosGripaisFull] = useState(getDadosGraficoRazaoAtendimentosGripais([], []));
    const [dadosGraficoTaxaMediaMovelFull, setDadosGraficoTaxaMediaMovelFull] = useState(getDadosGraficoTaxaMediaMovel([]));
    const [dadosGraficoTotalAtendimentosGripaisFull, setDadosGraficoTotalAtendimentosGripaisFull] = useState(getDadosGraficoTotalAtendimentosGripais([]));
    const [dadosGraficoTotalAtendimentosFull, setDadosGraficoTotalAtendimentosFull] = useState(getDadosGraficoTotalAtendimentos([]));
    const [dadosGraficoExcessoFull, setDadosGraficoExcessoFull] = useState(getDadosGraficoExcesso([]));
    const [dadosGraficoLimiteFull, setDadosGraficoLimiteFull] = useState(getDadosGraficoLimite([], []));

    const dadosGraficoTemperatura2MMaxValue = useRef(null);
    const dadosGraficoIndiceCalorMaxValue = useRef(null);
    const dadosGraficoHum2MMaxValue = useRef(null);
    const dadosGraficoPrecipitacaoMaxValue = useRef(null);
    const dadosGraficoAtendimentosGripaisMaxValue = useRef(null);
    const dataInicial = useRef(new Date("2023-01-01"));

    /*Fim Variáveis Gráficos*/

    const [selectedNames, setSelectedNames] = useState([]);

    const getMunicipioTodoPeriodo = (cod_ibge_local, pintaCirculo) => {
        cod_ibge_local = getCodIbgeCidadeWhereNome(cod_ibge_local);
        
        if (pintaCirculo && shapeFileMunicipios.current !== null) {
            
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {        
                if (cod_ibge_local === getCodIbgeShapefile(shapeFileMunicipios.current, shape).toString()) {
                  
                    shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightSelecao, color: "black", fillOpacity: opacidadeMunicipio });
                    if(cod_ibge != cod_ibge_local)
                        map.current.fitBounds(shapeFileMunicipios.current["_layers"][shape].getBounds().pad(1));
                } else {
                    let corBordaAnt = shapeFileMunicipios.current["_layers"][shape]["options"]["fillColor"];
                    if(corBordaAnt === "#dddcdf"){
                        corBordaAnt = "black";
                    }
                    if(corBordaAnt === corMapa1){
                        corBordaAnt = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                    } else if(corBordaAnt === cinzaDefaultMapaDark || corBordaAnt === cinzaDefaultMapaBorda || corBordaAnt === transparenciaDefaultMapa){
                        corBordaAnt = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda;
                    }                     

                    shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightNormal, color: corBordaAnt, fillOpacity: opacidadeMunicipio });
                }
            });            
        }

        var path_dir = "semanal";
        
        fetch('Dados/dados_municipios/' + path_dir + '/municipios/dados_' + cod_ibge_local + '.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
        .then(function (response) {
            //Limpa pontos anteriores
            return response.json();
        })
        .then(function (myJson) {
            setDados_Municipio_Selecionado(myJson);
        }); 
    }

    function checkDado(dado) {
        return dado['date'] === teste_data;
    }

    const handleChangeRangeData = (event, newValue) => {
        if(newValue[0] != minIdxDate){
            atualizaRangeGraficos(newValue[0], "min", true);
        } else if(newValue[1] != maxIdxDate) {    
            atualizaRangeGraficos(newValue[1], "max", true);
        }
    };

    /*const handleChangeDQIFiltroTabela = (event) => {
        setFiltroDQITabela(event.target.value);
    };*/

    const handleChangeDQIFiltroMapa = (event) => {
        setFiltroDQIMapa(event.target.value);
    };

    const handleChangeTendenciaCrescimentoFiltro = (event) => {
        setFiltroTendenciaCrescimento(event.target.value);
    };

    const trataNaNeNull = (dado) => {
        return dado === null || dado === "" || dado === "NaN" ? valorNil : dado;
    }

    const trataNaNeNullInt = (dado) => {
        return dado === null || dado === "" || dado === "NaN" ? valorNil : parseInt(dado);
    }

    function FormatarDoisDigitos(numero) {
        if(numero === null || numero === undefined)
            return "00";
        return numero.toString().padStart(2, '0');
    }

    function extraiAno(data){
        if(data && data.length > 0)
            return data.substring(0,4);
        else 
            return "";
    }

    function getDataLabelGrafico(context){
        const datasetIndex = context.datasetIndex;
        const dataPoint = context.dataIndex;

        var retorno = [];
        let nomeAmostraSesab = "atendimentos";

        if(context["dataset"]["label"] === t("dashboard.graficos.legenda_mm.2")){
          if(isAllSelect(municipioSelecionado)){
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            retorno.push(t("dashboard.graficos.legenda_mm.2") + ": " + fixaCasasDecimais(context["formattedValue"], 2) + "%");
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipios[dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipios[dataPoint]["data"]));
          } else {
            if(dados_municipio_selecionado === null)
              return ""; 
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            retorno.push(t("dashboard.graficos.legenda_mm.1") + ": " + fixaCasasDecimais(dados_municipio_selecionado["atendimentos"][dataPoint]["media_movel_ivas"], 2)  + "%");
            retorno.push(t("dashboard.graficos.legenda_mm.2") + ": " + fixaCasasDecimais(context["formattedValue"], 2)  + "%");
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipio_selecionado["atendimentos"][dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipio_selecionado["atendimentos"][dataPoint]["data"]));
          }
        } else if(context["dataset"]["label"] === t("dashboard.graficos.legenda_mm.1")) {
          if(isAllSelect(municipioSelecionado)){
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            retorno.push(t("dashboard.graficos.legenda_mm.1") + ": " + context["formattedValue"].replace(",", "."));
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipios[dataPoint]["semana"]))  + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipios[dataPoint]["data"]));
          } else {
            if(dados_municipio_selecionado === null)
              return "";
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);    
            retorno.push(t("dashboard.graficos.legenda_mm.1") + ": " + context["formattedValue"].replace(",", "."));
            retorno.push(t("dashboard.graficos.legenda_mm.2") + ": " + fixaCasasDecimais(dados_municipio_selecionado["atendimentos"][dataPoint]["taxagripais_atendtotais"], 2)  + "%");
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipio_selecionado["atendimentos"][dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipio_selecionado["atendimentos"][dataPoint]["data"]));
          }
        } if(context["dataset"]["label"] === t("dashboard.graficos.titulo_variacao_mm")){
          if(isAllSelect(municipioSelecionado)){
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            retorno.push(t("dashboard.graficos.titulo_variacao_mm") + ": " + fixaCasasDecimais(context["formattedValue"], 2)  + "%");
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipios[dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipios[dataPoint]["data"]));
          } else {
            if(dados_municipio_selecionado === null)
              return "";
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            retorno.push(t("dashboard.graficos.titulo_variacao_mm") + ": " + fixaCasasDecimais(context["formattedValue"], 2) + "%");
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipio_selecionado["atendimentos"][dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipio_selecionado["atendimentos"][dataPoint]["data"]));
          }
        } else if(context["dataset"]["label"] === t("dashboard.graficos.titulo_atendimento_gripais")) {
          if(isAllSelect(municipioSelecionado)){
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            retorno.push(t("dashboard.graficos.titulo_atendimento_gripais") + ": " + formataNumero(context.parsed.y), true);
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipios[dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipios[dataPoint]["data"]));
          } else {
            if(dados_municipio_selecionado === null)
              return "";
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            //retorno.push(t("dashboard.table.col2") + ": " + dados_municipio_selecionado["populacao"]);    
            retorno.push(t("dashboard.graficos.titulo_atendimento_gripais") + ": " + formataNumero(context.parsed.y, true));
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipio_selecionado[nomeAmostraSesab][dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipio_selecionado[nomeAmostraSesab][dataPoint]["data"]));
          }
        } else if(context["dataset"]["label"] === t("dashboard.graficos.titulo_limite")) {
          if(isAllSelect(municipioSelecionado)){
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            retorno.push(t("dashboard.graficos.titulo_limite") + ": " + formataNumero(context.parsed.y, true));
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipios[dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipios[dataPoint]["data"]));
          } else {
            if(dados_municipio_selecionado === null)
              return "";
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            //retorno.push(t("dashboard.table.col2") + ": " + preparaInt(dados_municipio_selecionado["populacao"]));
            retorno.push(t("dashboard.graficos.titulo_limite") + ": " + formataNumero(context.parsed.y, true));   
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipio_selecionado[nomeAmostraSesab][dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipio_selecionado[nomeAmostraSesab][dataPoint]["data"]));
          }
        } else if(context["dataset"]["label"] === t("dashboard.graficos.titulo_excesso")) {
          if(isAllSelect(municipioSelecionado)){
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            retorno.push(t("dashboard.graficos.titulo_excesso") + ": " + fixaCasasDecimais(context["formattedValue"], 2) + "%");
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipios[dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipios[dataPoint]["data"]));
          } else {
           if(dados_municipio_selecionado === null)
              return "";
            retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
            retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
            //retorno.push(t("dashboard.table.col2") + ": " + preparaInt(dados_municipio_selecionado["populacao"]));    
            retorno.push(t("dashboard.graficos.titulo_excesso") + ": " + fixaCasasDecimais(context["formattedValue"], 2) + "%");            
            retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipio_selecionado[nomeAmostraSesab][dataPoint]["semana"])) + espacoLegendaGrafico +
            t("dashboard.mesAno") + preparaMesAno(dados_municipio_selecionado[nomeAmostraSesab][dataPoint]["data"]));
          }
        } 
        return retorno;
      }

    function exibeMesAnoGrafico(index, values){
        const totalTicks = values.length;

        const date = moment(values[index].value);
        
        // Sempre mostre o primeiro e o último tick
        if (index === 0 || index === totalTicks - 1) {
            return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
        }
        // Lógica para mostrar ticks intermediários
        let intermediateTicks = 6; // número de ticks intermediários desejados

        let totalWeekTicks = Math.floor(totalTicks / 7);

        if(totalWeekTicks < intermediateTicks)
            intermediateTicks = totalWeekTicks - 2;
            if(intermediateTicks < 0)
                intermediateTicks = 0;

        const stepSize = Math.floor(totalTicks / (intermediateTicks + 1));

        if (index % stepSize === 0) {
            return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
        }

        return null; // Não mostra outros ticks
    }


    function getOptions_Line(theme, perc, titulo){    
        return {
          responsive: isResponsive,
          maintainAspectRatio: maintainAspectRatio,
          layout:{
            padding: {
                left: leftGrafico,
                right: rightGrafico,
                top: topGrafico,
                bottom: bottomGrafico
            }
          },
          plugins: {
              annotation: {
                  annotations: [
                    {
                      type: 'line',
                      mode: 'vertical',
                      scaleID: 'x',
                      value: dataSelecionada,
                      borderColor: corLinhaMarcaoGrafico,
                      borderWidth: 1,
                    },
                  ],
                },
            tooltip: {
              callbacks: {
                label: context => {
                  context["formattedValue"] = perc ? fixaCasasDecimais(context["formattedValue"],2) + "%" : context["formattedValue"];
                  if(context["element"]["options"]["backgroundColor"] === "rgba(255, 255, 255, 1)")
                    return "";
  
                  return getDataLabelGrafico(context);
                }
              }
            },
            legend: {
              display: true,
              onClick: null,
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: { 
                  size: 10
                }
              }
            },
            title: {
              display: true,
              text: titulo,
              color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
              position: 'top',
              font:{
                size: 13
              }
            },
          },
          scales: {
            y: {  // not 'yAxes: [{' anymore (not an array anymore)  
              ticks: {
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light, // not 'fontColor:' anymore
                // fontSize: 18,
                font: {
                  size: fontGrafico, // 'size' now within object 'font {}'
                },
                //stepSize: passo,
                beginAtZero: true,
                callback: function(value, index, ticks) {
                  return value + (perc === true ? '%' : '');
                }
              },
              beginAtZero: true,
            },
            x: {  // not 'xAxes: [{' anymore (not an array anymore)
              type: 'time',
              time: {
                parser: 'YYYY/MM/DD',
                unit: 'day',
                displayFormats: {
                 'day': 'DD/MM/YYYY',
                 'month' : 'DD/MM/YYYY'
                },
                
              },
              ticks:{
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: {
                  size: fontGrafico, // 'size' now within object 'font {}'
                },
                //stepSize: 7,
                callback: function(value, index, values) {
                    // Converte a data em um objeto moment
                    /*const date = moment(values[index].value);
                    // Retorna a semana e o ano no formato desejado
                    if (date.isoWeek() % 2 === 0) {
                        return `${FormatarDoisDigitos(date.isoWeek())}-${date.year()}`;
                    } else {
                        return null; // Pula a semana
                    }*/
                    return exibeMesAnoGrafico(index, values);
                },
              },
              min: datas != null ? datas[minIdxDate] : null,
              max: datas != null ? datas[maxIdxDate] : null,
            }
          }
          
        }
    }

    
    function getOptions(theme, titulo){
        return {
          responsive: isResponsive,
          maintainAspectRatio: false,
          layout:{
            padding: {
                left: leftGrafico,
                right: 12 + rightGrafico,
                top: topGrafico,
                bottom: bottomGrafico
              }
            
          },
          plugins: {
            annotation: {
                annotations: [
                  {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x',
                    value: dataSelecionada,
                    borderColor: corLinhaMarcaoGrafico,
                    borderWidth: 1,
                  },
                ],
              },
            tooltip: {
              callbacks: {
                label: context => {
                  if(context["element"]["options"]["backgroundColor"] === "rgba(255, 255, 255, 1)")
                    return "";
      
                  const datasetIndex = context.datasetIndex;
                  const dataPoint = context.dataIndex;
                  
                  if(!isAllSelect(estadoSelecionado) && isAllSelect(municipioSelecionado)){
                    var retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
                    retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
                    retorno.push(txtTotalAtendimentos.current + ": " + 
                      preparaInt(dados_estado.current["atendimentos"][dataPoint]["total_geral"]));
                    retorno.push(txtTotalGripal.current + ": " + 
                      preparaInt(dados_estado.current["atendimentos"][dataPoint]["total_gripal"]));            
                   /* retorno.push(txtTaxaAtendimentos.current + ": " + 
                    fixaCasasDecimais(dados_estado.current["atendimentos"][dataPoint]["taxagripais_atendtotais"],2) + "%");*/
                    
                    retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipios[dataPoint]["semana"])) + espacoLegendaGrafico +
                    t("dashboard.mesAno") + preparaMesAno(dados_municipios[dataPoint]["data"]));
                  } else
                  if(isAllSelect(municipioSelecionado)){
                    var retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
                    retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
                    retorno.push(txtTotalAtendimentos.current + ": " + 
                      preparaInt(dados_municipios[dataPoint]["total_geral"]));
                    retorno.push(txtTotalGripal.current + ": " + 
                      preparaInt(dados_municipios[dataPoint]["total_gripal"]));            
                   /* retorno.push(txtTaxaAtendimentos.current + ": " + 
                    fixaCasasDecimais(dados_municipios[dataPoint]["taxa"],2) + "%");*/
                    
                    retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipios[dataPoint]["semana"])) + espacoLegendaGrafico +
                    t("dashboard.mesAno") + preparaMesAno(dados_municipios[dataPoint]["data"]));
                  } else {
                    //var idxCidade = getIdxCidade(dados_municipios["municipios"], municipioSelecionado);
                    if(dados_municipio_selecionado === null)
                      return "";
                    let dataPointTemp = dataPoint;
                    
                    var retorno = [txtNomeEstado.current + ": " + estadoSelecionado];
                    retorno.push(txtNomeMunicipio.current + ": " + municipioSelecionado);
                    //retorno.push(t("dashboard.table.col2") + ": " + dados_municipio_selecionado["populacao"]);
                    retorno.push(txtTotalAtendimentos.current + ": " + 
                      preparaInt(dados_municipio_selecionado["atendimentos"][dataPointTemp]["total_geral"]));
                    retorno.push(txtTotalGripal.current + ": " + 
                      preparaInt(dados_municipio_selecionado["atendimentos"][dataPointTemp]["total_gripal"]));
                    
                    /*retorno.push(txtTaxaAtendimentos.current + ": " + 
                      (dados_municipio_selecionado["atendimentos"][dataPointTemp]["taxa"] === undefined ? fixaCasasDecimais(dados_municipio_selecionado["atendimentos"][dataPointTemp]["taxagripais_atendtotais"], 2)+ "%" :
                      fixaCasasDecimais(dados_municipio_selecionado["atendimentos"][dataPointTemp]["taxa"], 2)+ "%"));  */
                    retorno.push((t("dashboard.probabilidade_crescimento")) + ": " + 
                      fixaCasasDecimais(dados_municipio_selecionado["atendimentos"][dataPointTemp]["prob_gro_trend_ivas"]*100,2) + "%");                
                    
                    retorno.push(t("dashboard.semana_ep") + ": " + FormatarDoisDigitos(parseInt(dados_municipio_selecionado["atendimentos"][dataPointTemp]["semana"])) + espacoLegendaGrafico +
                    t("dashboard.mesAno") + preparaMesAno(dados_municipio_selecionado["atendimentos"][dataPointTemp]["data"]));   
                  }
                  return retorno;  
                }
              }
            },
            legend: {
              onClick: null,
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: { 
                  size: 10
                }
              }
            },
            title: {
              display: true,
              text: titulo,
              color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
              position: 'top',
              font:{
                size: 13
              }
            }
          },
          scales: { //voltar aqui
            y: {
              
                display: true,
                position: 'right',
                ticks: {
                    color: theme === "dark" ? texto_grafico_dark : texto_grafico_light, // not 'fontColor:' anymore
                    font:{
                        fontSize: fontGrafico
                    },
                    /*callback: function(value, index, ticks) {
                        return preparaInt(value);
                    }*/    
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'left',
                ticks: {
                    color: theme === "dark" ? texto_grafico_dark : texto_grafico_light, // not 'fontColor:' anymore
                    font:{
                        fontSize: fontGrafico
                    },
                    beginAtZero: true,
                    callback: function(value, index, ticks) {
                        return preparaInt(value);
                    }    
                },
                grid: {
                    borderWidth: 3,
                    //drawOnChartArea: false // Evita sobreposição de linhas de grade com barras
                  }
            },
            
            x: {  
              type: 'time',
              time: {
                parser: 'YYYY/MM/DD',
                unit: 'day',
                displayFormats: {
                  'day': 'DD/MM/YYYY',
                  'month' : 'DD/MM/YYYY'
                },
              },
              ticks:{
                color: theme === "dark" ? texto_grafico_dark : texto_grafico_light,
                font: {
                  size: fontGrafico, // 'size' now within object 'font {}'
                },
                //stepSize: 7,
                callback: function(value, index, values) {
                    
                    return exibeMesAnoGrafico(index, values);
                    
                },
              },
              min: datas != null ? datas[minIdxDate] : null,
              max: datas != null ? datas[maxIdxDate] : null,
            }
          },
          
        }
      }          

      const formataNumero = (number, preparaInt) => {
        return new Intl.NumberFormat(undefined, {
            useGrouping: true,
            minimumFractionDigits: preparaInt ? 0 : 2,
            maximumFractionDigits: preparaInt ? 0 : 2,
            minimumIntegerDigits: 1,
            style: 'decimal',
            currency: undefined,
            currencyDisplay: 'symbol',
          }).format(number);
      };

      
    
    function getCodEstadoByName(nome_estado)
    {
        let retorno = "";
        /*Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
            if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"] === nome_estado){ 
                retorno = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["CD_UF"];
            }
        });*/
        const keys = Object.keys(shapeFileBrasil.current["_layers"]);
        if (keys.length > 0) {
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                const element = shapeFileBrasil.current["_layers"][key];
                if(element !== undefined && element["feature"]["properties"]["NM_UF"] === nome_estado){ 
                    retorno = element["feature"]["properties"]["CD_UF"];
                    break;
                }
            }
        } 
        return retorno;
    }

    function handleChangeEstadoTabela (estado_selecionado){
        setLoading(true);
        setEstadoSelecionado(estado_selecionado); 
        setCod_Ibge(t("dashboard.todos"));
        setMunicipioSelecionado(t("dashboard.todos"));
    }

    function atualizaRetangulos (excesso_glm, excesso_ens, excesso_ml, excesso_ears, excesso_evi, evi, ears, populacao, total_gripal, total_atendimentos, probabilidade_crescimento, total_gripal_ant, total_atendimentos_ant){
        setNumAtendimentosGripais(total_gripal);
        
        if(indice.toLowerCase() === "ml"){
            setExcesso(fixaCasasDecimais(excesso_ml, 2)) ;
        } else if(indice.toLowerCase() === "ens"){
            setExcesso(fixaCasasDecimais(excesso_ens, 2)) ;
        } else if(indice.toLowerCase() === "glm"){
            setExcesso(fixaCasasDecimais(excesso_glm, 2)) ;
        } else {
            setExcesso(indice.toLowerCase() === "evi" ? fixaCasasDecimais(excesso_evi, 2): fixaCasasDecimais(excesso_ears, 2));
        }
        
        setPopulacao(populacao);
        setEviRetangulo(evi);
        setEarsRetangulo(ears);
        setAtendimentosTotaisRetangulo(total_atendimentos);
        setProbabilidadeDeCrescimentoRetangulo(probabilidade_crescimento != null ? probabilidade_crescimento * 100 : probabilidade_crescimento);
        
        setNumAtendimentosGripaisAnterior(total_gripal_ant);
        setAtendimentosTotaisRetanguloAnterior(total_atendimentos_ant);
    
    }

  //useEffect

  useEffect(() => {
    let dtInicial = datas[idxDataSelecionada];
    dataInicial.current = getLastYear(dtInicial);

    let idxDataInicio = getIdxDataInicio(datas);

    setMinDate(datas[idxDataInicio]);
    setMinDateRangeInicial(inicioSemanas[idxDataInicio]);
    setMinDateRangeFinal(fimSemanas[idxDataInicio]);
    setMinIdxDate(idxDataInicio);
    setMaxDate(datas[idxDataSelecionada]);
    setMaxIdxDate(idxDataSelecionada);
    
    setMaxDateRangeInicial(inicioSemanas[idxDataSelecionada]);
    setMaxDateRangeFinal(fimSemanas[idxDataSelecionada]);
    /*atualizaRangeGraficos(idxDataInicio, "min", false);
    atualizaRangeGraficos(idxDataSelecionada, "max", false);*/
    setValueRangeData([idxDataInicio,idxDataSelecionada]);
    setSemanaSelecionada(semanas[idxDataSelecionada]);
    setInicioSemanaSelecionada(inicioSemanas[idxDataSelecionada]);
    setFimSemanaSelecionada(fimSemanas[idxDataSelecionada]);
    setInicioSemanaSelecionadaRange(semanas[idxDataInicio]);
    setFimSemanaSelecionadaRange(semanas[idxDataSelecionada]);

    /*if(idxDataSelecionada < minIdxDate){
        atualizaRangeGraficos(idxDataSelecionada, "min");
        setTimeout(function () {
            atualizaRangeGraficos(idxDataSelecionada, "max");
        }, 1000);
    } else {
        atualizaRangeGraficos(idxDataSelecionada, "max");
        let dtInicial = datas[idxDataSelecionada];
        dataInicial.current = getLastYear(dtInicial);

        let idxDataInicio = getIdxDataInicio(datas);
        atualizaRangeGraficos(idxDataInicio, "min");
    }*/
        //parei aqui
        getDadosPorData(datas[idxDataSelecionada], language, true);
        
        console.log("useEffect idxDataSelecionada");
        atualizaTotalSinais(!isAllSelect(estadoSelecionado));

    }, [idxDataSelecionada]);

    const recarregaCache = () => {
        window.location.reload(true);
    };

    const fetchData = async () => {
        const response = await fetch('/Dados/data.json', {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          },
        });
        const data = await response.json();
        let dataCache = new Date (localStorage.getItem('dataCacheFiles'));
        let dataArquivo = new Date(data["data"]);
  
        //comparar data do arquivo com a data do cache
        let dataComparacao  = new Date(localStorage.getItem('dataCacheFiles'));
        if(dataCache)
            dataComparacao.setHours(dataCache.getHours() + 1);
        
       if(localStorage.getItem('dataCacheFiles') && (dataComparacao < dataArquivo)){
          localStorage.setItem('dataCacheFiles', dataArquivo.toString());
          console.log('Recarregando cache');
          recarregaCache();
          console.log('Recarregando cache');
        } else {
          localStorage.setItem('dataCacheFiles', dataArquivo.toString());
        }
      };

    useEffect(() => {     
        var dominio = window.location.hostname;

        if(dominio.includes("arbov")){
            dengue = false;
        } else {
            dengue = true;
        }

        fetchData();

        if(dominio.includes("aesopdev")){
            setLink_Zika("https://aesoparbovdev.outerlamce.com/");
        } else if(dominio.includes("testeaesop.")) {
            setLink_Zika("https://testeaesoparbov.outerlamce.com/");
        } else {
            setLink_Zika("https://aesoparbov.outerlamce.com/");
        }

        if(dominio.includes("aesoparbovdev")){
            link_gripe = "https://aesopdev.outerlamce.com/";
        } else if(dominio.includes("teste")) {
            link_gripe = "https://testeaesop.outerlamce.com/";
        } else {
            link_gripe = "https://aesop.outerlamce.com/";
        }

        //// Use fetch para carregar o arquivo JSON
        fetch('Dados/dados_pais/dados_brasil.json')
        .then(response => response.json())
        .then(data => {
            setDados_Municipios(data); // Atribui os dados JSON à variável global
        })
        .catch(error => {
            console.error('Erro ao carregar o arquivo JSON:', error);
        });

        getSemana();

        if (shapeFileBrasil.current === null) {
            shapeFileBrasil.current = L.geoJSON(geojsonBrasil, {
                interactive: false,
                style: function (feature) {
                    return { color: transparenciaDefaultMapa, fillColor: transparenciaDefaultMapa, stroke: true, weight: weightNormal, fillOpacity: transparencia.current };
                },
                /*onEachFeature: onEachFeatureBrasil,*/
            });
        }

        if (shapeFileMunicipios.current === null) {
            shapeFileMunicipios.current = L.geoJSON(geojsonMunicipios, {
                style: function (feature) {
                    return { color: transparenciaDefaultMapa, fillColor: transparenciaDefaultMapa, stroke: true, weight: weightNormal, fillOpacity: opacidadeMunicipio };
                },
                onEachFeature: onEachFeatureEstado,
            });
        }

        iniciou.current = true;
        localStorage.setItem('posicaoScroll', JSON.stringify(0));
        getDadosPorData(datas[idxDataSelecionada], language, true);
        //setDados_Municipios(dados_municipios_semanal);

        setDataCache(localStorage.getItem('dataCacheFiles')); 
    }, []);

    const resetShapeFile = (estadoLimpar, municipioLimpar, limparSelecao) => {
        if(!map.current)
            return;
        map.current.eachLayer(layer => {
          if (layer instanceof L.GeoJSON) {
            // A camada é do tipo L.GeoJSON
                
                if(layer["options"]["onEachFeature"]["name"] !== "onEachFeatureBrasil"){
                    //console.log('Encontrada uma camada L.geoJSON Estado:', layer);
                    const keys = Object.keys(layer["_layers"]);
                    if (keys.length > 0) {
                        const firstKey = keys[0];
                        const firstItem = layer["_layers"][firstKey];
                        if(getSiglaEstadoByNome(estadoLimpar) === layer["_layers"][firstKey]["feature"]["properties"]["SIGLA_UF"]){   
                            limpaShapeFile(layer, estadoLimpar, municipioLimpar, limparSelecao);
                        }
                    }        
                } else {
                    //console.log('Encontrada uma camada L.geoJSON Pais:', layer);
                }
                //Object.keys(layer).
                //layer['shape'].setStyle({ weight: 1, color: cinzaDefaultMapa, fillColor: cinzaDefaultMapa, fillOpacity: 0.5 });   
            
            // Aqui você pode fazer operações adicionais com a camada L.geoJSON
          }
        });
    };

    const carregaTabelaPorEstado = (codigo_estado) => {
        
        if(dados_municipios_por_data !== null){
            let tempCidadesTabela = [];
            for(let x = 0; x < dados_municipios_por_data["municipios"].length; x++){       
                /*tempMakersMediaMovel.push(
                    criaTempMakersMediaMovelMunicipal(dados_municipios_por_data["municipios"][x],  dados_municipios_por_data['data'], dados_municipios_por_data['semana'])
                    );*/
                if(!isAllSelect(estadoSelecionado) && dados_municipios_por_data["municipios"][x]["cod_ibge"].toString().startsWith(codigo_estado)){
                    tempCidadesTabela.push(
                        criaTempMakersMediaMovelMunicipal(dados_municipios_por_data["municipios"][x], dados_municipios_por_data['data'], dados_municipios_por_data['semana'])
                        );
                }
            }  
            
            if(!isAllSelect(estadoSelecionado)){
                setMarkers(tempCidadesTabela);
                
            }
            /*setMarkers(!isAllSelect(estadoSelecionado) ? tempCidadesTabela : tempMakersMediaMovel);*/
        }
    }

    const atualizaTotalSinais = (isEstadual, dados) => {
        let semanaComparacao = semanas[idxDataSelecionada];
        if(semanaComparacao === undefined){
            semanaComparacao = semanaSelecionada;
        } 

        let dadosTemp = null;
        if(dados){
            console.log("passei aqui2", dados["municipios"]);
            dadosTemp = dados["municipios"];
        } else if(dados_municipios_por_data){ 
            console.log("passei aqui3", dados_municipios_por_data["municipios"]);
            dadosTemp = dados_municipios_por_data["municipios"];
        }

        let campoSinal = "";
        if(indice.toLowerCase() === "glm"){
            campoSinal = "sinal_glm_ivas";
        } else if(indice.toLowerCase() === "ml"){
            campoSinal = "sinal_ml_ivas";
        } else if(indice.toLowerCase() === "ears"){
            campoSinal = "sinal_ears_ivas";
        } else if(indice.toLowerCase() === "evi"){
            campoSinal = "sinal_evi_ivas";
        } else if(indice.toLowerCase() === "ens"){
            campoSinal = "sinal_ens_ivas";
        }

        if(dadosTemp){
            let codigo_estado = getCodEstadoByName(estadoSelecionado);
            let totalSinaisTemp = 0;
            let totalSinaisAntTemp = 0; 
            for (let index = 0; index < dadosTemp.length; index++) {
                const element = dadosTemp[index];
                if((!isEstadual || element['cod_uf'].toString() === codigo_estado) && element[campoSinal] === 1 && element["dqi"] === "Apto"){
                    totalSinaisTemp++;
                } 
                if((!isEstadual || element['cod_uf'].toString() === codigo_estado) && element[campoSinal+"_ant"] === 1 && element["dqi"] === "Apto"){
                    totalSinaisAntTemp++;
                }   
            }
            setTotalSinais(totalSinaisTemp);
            setTotalSinaisAnt(totalSinaisAntTemp);
            console.log("Total de sinais:", totalSinaisAntTemp);
        }
    }

    const addMarker = (coords, icon) => {
        if (markersLayer) {
          const newMarker = L.marker(coords, { icon }).addTo(markersLayer);
        }
      };
    
    const removeMarkers = () => {
        markersLayer.clearLayers(); // Removendo todos os marcadores da layer
    };

    const handleToggleMarkers = (event) => {
        const isChecked = event.target.checked;

        if (isChecked) {
            markersLayer.addTo(map.current); // Adicionando a layer ao mapa se estiver oculta
        } else {
            map.current.removeLayer(markersLayer); // Removendo a layer do mapa se estiver visível
        }
    };

    const createIcon = (iconUrl) => {
        return L.icon({
          iconUrl: iconUrl,
          iconSize: [20, 20],
          iconAnchor: [10, 10],
        });
      };

    useEffect(() => {
        if(!iniciou.current)
            return;

        var url = theme === "dark" ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";

        const { innerWidth: width, innerHeight: height } = window;

        layerTile.current = L.tileLayer(url, {
            zoomControl: true,
            zoomSnap: zoomSnap_,
            zoomDelta: zoomSnap_,
            wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
            defaultZoom: defaultZoom,
            maxZoom: 18,
            id: 'mapbox.streets'
        });

        if (map.current === null) {
            map.current = L.map("map", {
                minZoom: 3,
                maxZoom: 10,
                layers: [layerTile.current]
            });
            //shapeFileBrasilExcesso.current.addTo(map.current);
        } else {
            map.current.invalidateSize();
        }

        const defaultCenter = position;

        if(width < 1300)
            map.current.setView([-13.338793, -45.206666], defaultZoom);
        else 
            map.current.setView(defaultCenter, defaultZoom);

        if(shapeFileMunicipios.current === null){
            shapeFileMunicipios.current = L.tileLayer('');
        }

        shapeFileMunicipios.current.addTo(map.current);
        shapeFileBrasil.current.addTo(map.current);

        setMarkersLayer(L.layerGroup().addTo(map.current)); // Criando uma layer para os marcadores e adicionando ao mapa

        const lineCoordinates = [
            [-22.890090, -42.029405], // Latitude e longitude do ponto inicial
            [-12.924167, -38.440701],  // Latitude e longitude do ponto final
          ];

        const lineCoordinates2 = [
            [-23.62340166156419, -46.65858576087318], // Latitude e longitude do ponto inicial
            [-12.988999467983497, -38.50386516595166],  // Latitude e longitude do ponto final
          ];

        if (map.current) {
            // Adiciona um ouvinte de evento para o evento de zoomend
            const onZoomEnd = (event) => {
              // Obtém o nível de zoom atual
              const currentZoom = event.target.getZoom();

              if(theme === "dark"){
                if(currentZoom <=  5){
                    weightNormal = 0.5;
                }  else {
                    weightNormal = 1;
                }
              }     
            };
      
            // Adiciona o ouvinte de evento ao mapa para o evento de zoomend
            map.current.on('zoomend', onZoomEnd);
        }

        map.current.on('overlayadd', function(event){
            const selectedLayer = event.target.value;

            setNomeLayerAtiva(event.name);
        }); 
        
        criarLegenda(0,0,"ind", language);
    }, [map]);

    useEffect(() => {
        let siglaEstado = '';
        let codigo_estado = 0;

        if(shapeFileBrasil.current !== null){
            Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
                if(isAllSelect(estadoSelecionado)){
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: weightNormal, color: /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaBrasilWhite*/transparenciaDefaultMapa, fillColor: /*'#E63100'*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/ transparenciaDefaultMapa, fillOpacity: 0.5 });
                } else
                if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"] === estadoSelecionado){ 
                    siglaEstado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["SIGLA_UF"];
                    codigo_estado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["CD_UF"];
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: 2, color: "black" /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaBrasilWhite/*transparenciaDefaultMapa*/, fillColor: /*'#E63100'*//*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/ transparenciaDefaultMapa, fillOpacity: 0.5 });
                } else {
                    shapeFileBrasil.current["_layers"][shape].setStyle({ weight: weightNormal, color: /*transparenciaDefaultMapa*/"black", fillColor: /*transparenciaDefaultMapa*/ "black", fillOpacity: transparencia.current });   
                }
            });
            carregaTabelaPorEstado(codigo_estado);
        }

        if(markersLayer !== null){
            removeMarkers();
        }

        if(isAllSelect(estadoSelecionado) && !isAllSelect(municipioSelecionado)){
            setMunicipioSelecionado(t("dashboard.todos"));
        }

        /*console.log("useEffect");*/

        if(shapeFileMunicipios.current !== null){
            Object.keys(shapeFileMunicipios.current["_layers"]).forEach((shape) => {
                let fillOpacity = transparencia.current;
                let corBordaAnt = shapeFileMunicipios.current["_layers"][shape]["options"]["fillColor"];
                let corBordaAtual = shapeFileMunicipios.current["_layers"][shape]["options"]["color"];
                let weightLocal = weightNormal;
                if(!isAllSelect(municipioSelecionado) && (corBordaAtual === "white" || corBordaAtual === "#757575")){
                    corBordaAnt = "black";
                    weightLocal = weightSelecao;
                    fillOpacity = opacidadeMunicipio;
                }
                else
                if(corBordaAnt === corMapa1){
                    corBordaAnt = theme === "dark" ? corMapa1BordaDark : corMapa1Borda;
                } else if(corBordaAnt === corMapa3){
                    corBordaAnt = corMapa3_Escuro;
                } else {
                    corBordaAnt = theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda;;  
                }

                shapeFileMunicipios.current["_layers"][shape].setStyle({ weight: weightLocal, color: corBordaAnt, fillOpacity: opacidadeMunicipio  });
            });
        }

        if(!isAllSelect(municipioSelecionado)){
            return;
        }

        setLoading(true);

        if(shapeFileBrasil.current !== null){
            if(codigo_estado === 0){
                setListaMunicipios(getCidades());
                let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
                setCidades_Labels(cidades_labels_tmp);
            }
            
            carregaDadosEstado(codigo_estado, siglaEstado);
        }
    }, [estadoSelecionado, municipioSelecionado]);

    useEffect(() =>{
        
        let dadosTemp = [];
        let dados2Temp = [];
        let dados3Temp = [];
        let dados4Temp = [];
        let dados_variacao_media_movelTemp = [];
        let dados_media_movelTemp = [];
        
        let dadosEVI = [];
        let dadosMU = [];
        let dadosEXC = [];
        
        let dados_o_numTemp = [];
        let dados_e_thrTemp = [];
        let dados_u_b_thrTemp = [];
        let dados_e_h_estimateTemp = [];
        let dados_p_e_h_estimateTemp = [];

        let dadosProbGroTrend = [];

        var cod_ibge_local = null;

        if(dataSelecionada === undefined){
            return;
        }

        if (dados_municipio_selecionado != null && !isAllSelect(municipioSelecionado)) {
            
            cod_ibge_local = dados_municipio_selecionado["cod_ibge"];

            var municipio_sel = '';

            var cod_ibge_selecionado = String(dados_municipio_selecionado["cod_ibge"]);
            cod_ibge_selecionado = cod_ibge_selecionado.substring(0, 6);

            setMunicipioSelecionado(dados_municipio_selecionado["municipio"]);
            municipio_sel = dados_municipio_selecionado["municipio"];

            var acumulado_gripal = 0;

            var populacao = 0;

            console.log("useEffect dados_municipio_selecionado");
            atualizaTotalSinais(true);

            for (let index = 0; index < dados_municipios_por_data["municipios"].length; index++) {
                const element = dados_municipios_por_data["municipios"][index];
                if(element["cod_ibge"] === dados_municipio_selecionado["cod_ibge"]){
                    populacao = (dados_municipios_por_data["municipios"][index]['populacao']);
                    break;
                }
            }
            //console.log(dados_municipio_selecionado['populacao']);
                
            let total_gripal_ant = 0;
            let total_atendimentos_ant = 0;
            legendaSemana = [];
            dados_municipio_selecionado["atendimentos"].forEach(function (atendimento) {
                var dataDadoAtual = new Date(atendimento['data']);
                var semanaDadoAtual = atendimento['semana'];
                legendaSemana.push(semanaDadoAtual);
                //console.log(atendimento);
                if(new Date(dataSelecionada).getTime() == dataDadoAtual.getTime()){
                    
                    total_gripal_ant = atendimento["total_gripal_ant"];
                    total_atendimentos_ant = atendimento["total_geral_ant"];
                    console.log("BBB:" +  atendimento["total_geral_ant"]);
                    atualizaRetangulos (                atendimento["exc_glm_ivas"],
                                                        atendimento["exc_ens_ivas"],
                                                        atendimento["exc_ml_ivas"],
                                                        atendimento["exc_ears_ivas"],
                                                        atendimento["exc_evi_ivas"], 
                                                        atendimento["sinal_evi_ivas"], 
                                                        atendimento["sinal_ears_ivas"], 
                                                        populacao, 
                                                        atendimento["total_gripal"], atendimento["total_geral"], atendimento["prob_gro_trend_ivas"], total_gripal_ant, total_atendimentos_ant);
                    
                }


                var obj = {};
                obj['data'] = atendimento["data"];
                obj['total'] = atendimento["total_geral"];
                dadosTemp.push(obj);

                var obj2 = {};
                obj2['data'] = atendimento["data"];
                obj2['total'] = atendimento["total_gripal"];
                
                dados2Temp.push(obj2);

                if(atendimento["data"] <= maxDate && atendimento["data"] >= minDate){
                    if(dadosGraficoAtendimentosGripaisMaxValue.current === null || dadosGraficoAtendimentosGripaisMaxValue.current < obj2['total']){
                        dadosGraficoAtendimentosGripaisMaxValue.current = obj2['total'];
                    }
                } 

                var obj3 = {};
                obj3['data'] = atendimento["data"];
                obj3['total'] = (atendimento["total_gripal"] / atendimento["total_geral"]) * 100;
                dados3Temp.push(obj3);

                acumulado_gripal += atendimento["total_gripal"];
                var obj4 = {};
                obj4['data'] = atendimento["data"];
                obj4['total'] = acumulado_gripal;
                dados4Temp.push(obj4);

                var obj5 = {};
                obj5['data'] = atendimento["data"];
                obj5['total'] = atendimento["media_movel_ivas"];
                dados_media_movelTemp.push(obj5);

                var obj6 = {};
                obj6['data'] = atendimento["data"];
                obj6['total'] = atendimento["variacao_media_movel"];
                dados_variacao_media_movelTemp.push(obj6);

                var obj7 = {};
                obj7['data'] = atendimento["data"];
                obj7['total'] = atendimento["sinal_evi_ivas"];
                dadosEVI.push(obj7);

                var obj8 = {};
                obj8['data'] = atendimento["data"];
                if(indice.toLowerCase() === "ml"){
                    obj8['total'] = atendimento["lim_esp_ml_ivas"];
                } else if(indice.toLowerCase() === "ens"){
                    obj8['total'] = atendimento["lim_esp_ens_ivas"];
                } else if(indice.toLowerCase() === "evi"){
                    obj8['total'] = atendimento["lim_esp_evi_ivas"];
                } else if(indice.toLowerCase() === "ears"){
                    obj8['total'] = atendimento["lim_esp_ears_ivas"];
                } else if(indice.toLowerCase() === "glm"){
                    obj8['total'] = atendimento["lim_esp_glm_ivas"];
                }
                //obj8['total'] = indice.toLowerCase() === "ml" ? atendimento["lim_esp_ml_ivas"] : indice.toLowerCase() === "ens" ? atendimento["lim_esp_ens_ivas"] : indice.toLowerCase() === "evi" ? atendimento["lim_esp_evi_ivas"] : atendimento["lim_esp_ears_ivas"];
                dadosMU.push(obj8);

                var obj9 = {};
                obj9['data'] = atendimento["data"];

                if(indice.toLowerCase() === "ml"){
                    obj9['total'] = atendimento["exc_ml_ivas"];
                } else if(indice.toLowerCase() === "ens"){
                    obj9['total'] = atendimento["exc_ens_ivas"];
                } else if(indice.toLowerCase() === "evi"){
                    obj9['total'] = atendimento["exc_evi_ivas"];
                } else if(indice.toLowerCase() === "ears"){
                    obj9['total'] = atendimento["exc_ears_ivas"];
                } else if(indice.toLowerCase() === "glm"){
                    obj9['total'] = atendimento["exc_glm_ivas"];
                }

                //obj9['total'] = indice.toLowerCase() === "ml" ? atendimento["exc_ml_ivas"] : indice.toLowerCase() === "ens" ? atendimento["exc_ens_ivas"] : indice.toLowerCase() === "evi" ? atendimento["exc_evi_ivas"] : atendimento["exc_ears_ivas"];
                dadosEXC.push(obj9);

                var obj10 = {};
                obj10['data'] = atendimento["data"];
                obj10['total'] =  fixaCasasDecimais(atendimento["prob_gro_trend_ivas"]*100, 2);//atendimento["prob_gro_trend_ivas"];
                dadosProbGroTrend.push(obj10);
                

            });          
        } else {
            if(estadoSelecionado !== t("dashboard.todos") && dados_estado.current !== null){
                console.log("useEffect dados_estado");
                atualizaTotalSinais(true);

                for (let index = 0; index < dados_estado.current["atendimentos"].length; index++) {
                    const element = dados_estado.current["atendimentos"][index];

                    var obj = {};
                    obj['data'] = element["data"];
                    obj['total'] = element["total_geral"];
                    dadosTemp.push(obj);

                    var obj2 = {};
                    obj2['data'] = element["data"];
                    obj2['total'] = element["total_gripal"];
                    dados2Temp.push(obj2);

                    if(element["data"] <= maxDate && element["data"] >= minDate){
                        if(dadosGraficoAtendimentosGripaisMaxValue.current === null || dadosGraficoAtendimentosGripaisMaxValue.current < obj2['total']){
                            dadosGraficoAtendimentosGripaisMaxValue.current = obj2['total'];
                        }
                    } 

                    var obj3 = {};
                    obj3['data'] = element["data"];
                    obj3['total'] = (element["total_gripal"] / element["total_geral"]) * 100;
                    dados3Temp.push(obj3);

                    acumulado_gripal += element["total_gripal"];

                    var obj4 = {};
                    obj4['data'] = element["data"];
                    obj4['total'] = acumulado_gripal;
                    dados4Temp.push(obj4);

                    var obj5 = {};
                    obj5['data'] = element["data"];
                    obj5['total'] = element["media_movel_ivas"];
                    dados_media_movelTemp.push(obj5);

                    var obj6 = {};
                    obj6['data'] = element["data"];
                    obj6['total'] = element["variacao_media_movel"];
                    dados_variacao_media_movelTemp.push(obj6);

                    var obj8 = {};
                    obj8['data'] = element["data"];
                    if(indice.toLowerCase() === "ml"){
                        obj8['total'] = element["lim_esp_ml_ivas"];
                    } else if(indice.toLowerCase() === "ens"){
                        obj8['total'] = element["lim_esp_ens_ivas"];
                    } else if(indice.toLowerCase() === "evi"){
                        obj8['total'] = element["lim_esp_evi_ivas"];
                    } else if(indice.toLowerCase() === "ears"){
                        obj8['total'] = element["lim_esp_ears_ivas"];
                    } else if(indice.toLowerCase() === "glm"){
                        obj8['total'] = element["lim_esp_glm_ivas"];
                    }
                    //obj8['total'] = indice.toLowerCase() === "ml" ? element["lim_esp_ml_ivas"] : indice.toLowerCase() === "ens" ? element["lim_esp_ens_ivas"] : indice.toLowerCase() === "evi" ? element["lim_esp_evi_ivas"] : element["lim_esp_ears_ivas"];
                    dadosMU.push(obj8);

                    var obj9 = {};
                    obj9['data'] = element["data"];
                    if(indice.toLowerCase() === "ml"){
                        obj9['total'] = element["exc_ml_ivas"];
                    } else if(indice.toLowerCase() === "ens"){
                        obj9['total'] = element["exc_ens_ivas"];
                    } else if(indice.toLowerCase() === "evi"){
                        obj9['total'] = element["exc_evi_ivas"];
                    } else if(indice.toLowerCase() === "ears"){
                        obj9['total'] = element["exc_ears_ivas"];
                    } else if(indice.toLowerCase() === "glm"){
                        obj9['total'] = element["exc_glm_ivas"];
                    }
                    //obj9['total'] = indice.toLowerCase() === "ml" ? element["exc_ml_ivas"] : indice.toLowerCase() === "ens" ? element["exc_ens_ivas"] : indice.toLowerCase() === "evi" ? element["exc_evi_ivas"] : element["exc_ears_ivas"];
                    dadosEXC.push(obj9);

                   /* var obj10 = {};
                    obj10['data'] = element["data"];
                    obj10['total'] = element["prob_gro_trend_ivas"];
                    dadosProbGroTrend.push(obj10);*/
                }
            } else if(dados_pais !== null){
                console.log("useEffect dados_pais");
                atualizaTotalSinais(false);
                let total_gripal_ant = 0;
                let total_atendimentos_ant = 0;
                dados_pais.forEach(function (dado) {   
                    console.log(dado);               
                    if(new Date(dataSelecionada).getTime() === new Date(dado["data"]).getTime()){
                        total_gripal_ant = dado["total_gripal_ant"];
                        total_atendimentos_ant = dado["total_geral_ant"];
                        console.log("CCC:" +  total_gripal_ant);
                        atualizaRetangulos (dado["exc_glm_ivas"],
                                            dado["exc_ens_ivas"],
                                            dado["exc_ml_ivas"],
                                            dado["exc_ears_ivas"],
                                            dado["exc_evi_ivas"], 
                                            dado["sinal_evi_ivas"], 
                                            "NaN", 
                                            dado["populacao"], 
                                            dado["total_gripal"], 
                                            dado["total_geral"], 
                                            dado["prob_gro_trend_ivas"], total_gripal_ant, total_atendimentos_ant);

                        /*setNumAtendimentosGripais(dado["total_gripal"]);
                        setEviRetangulo(fixaCasasDecimais(dado["evi"], 2));
                        setTxCrescimento(fixaCasasDecimais(dado["variacao_media_movel"], 2));
                        setExcesso(dado["exc"]);
                        setPopulacao(dado["populacao"]);*/
                        
                    }



                    var obj = {};
                    obj['data'] = dado["data"];
                    obj['total'] = dado["total_geral"];
                    dadosTemp.push(obj);

                    var obj2 = {};
                    obj2['data'] = dado["data"];
                    obj2['total'] = dado["total_gripal"];
                    dados2Temp.push(obj2);


                    var obj3 = {};
                    obj3['data'] = dado["data"];
                    obj3['total'] = (dado["total_gripal"] / dado["total_geral"]) * 100;
                    dados3Temp.push(obj3);

                    acumulado_gripal += dado["total_gripal"];
                    var obj4 = {};
                    obj4['data'] = dado["data"];
                    obj4['total'] = acumulado_gripal;
                    dados4Temp.push(obj4);

                    var obj5 = {};
                    obj5['data'] = dado["data"];
                    obj5['total'] = dado["media_movel_ivas"];
                    dados_media_movelTemp.push(obj5);

                    var obj6 = {};
                    obj6['data'] = dado["data"];
                    obj6['total'] = dado["variacao_media_movel"];
                    dados_variacao_media_movelTemp.push(obj6);

                    var obj13 = {};
                    obj13['data'] = dado["data"];
                    if(indice.toLowerCase() === "ml"){
                        obj13['total'] = dado["lim_esp_ml_ivas"];
                    } else if(indice.toLowerCase() === "ens"){
                        obj13['total'] = dado["lim_esp_ens_ivas"];
                    } else if(indice.toLowerCase() === "evi"){
                        obj13['total'] = dado["lim_esp_evi_ivas"];
                    } else if(indice.toLowerCase() === "ears"){
                        obj13['total'] = dado["lim_esp_ears_ivas"];
                    } else if(indice.toLowerCase() === "glm"){
                        obj13['total'] = dado["lim_esp_glm_ivas"];
                    }
                    //obj13['total'] = indice.toLowerCase() === "ml" ? dado["lim_esp_ml_ivas"] : indice.toLowerCase() === "ens" ? dado["lim_esp_ens_ivas"] : indice.toLowerCase() === "evi" ? dado["lim_esp_evi_ivas"] : dado["lim_esp_ears_ivas"];
                    dadosMU.push(obj13);

                    var obj14 = {};
                    obj14['data'] = dado["data"];
                    if(indice.toLowerCase() === "ml"){
                        obj14['total'] = dado["exc_ml_ivas"];
                    } else if(indice.toLowerCase() === "ens"){
                        obj14['total'] = dado["exc_ens_ivas"];
                    } else if(indice.toLowerCase() === "evi"){
                        obj14['total'] = dado["exc_evi_ivas"];
                    } else if(indice.toLowerCase() === "ears"){
                        obj14['total'] = dado["exc_ears_ivas"];
                    } else if(indice.toLowerCase() === "glm"){
                        obj14['total'] = dado["exc_glm_ivas"];
                    }
                    //obj14['total'] = indice.toLowerCase() === "ml" ? dado["exc_ml_ivas"] : indice.toLowerCase() === "ens" ? dado["exc_ens_ivas"] : indice.toLowerCase() === "evi" ? dado["exc_evi_ivas"] : dado["exc_ears_ivas"];
                    dadosEXC.push(obj14);

                    /*
                    var obj15 = {};
                    obj15['data'] = dado["data"];
                    obj15['total'] = dado["prob_gro_trend_ivas"];
                    dadosProbGroTrend.push(obj15);*/
                });
            } else {
                dadosTemp = [];
                dados2Temp = [];
                dados3Temp = [];
                dados4Temp = [];
                dados_variacao_media_movelTemp = [];
                dados_media_movelTemp = [];

                dados_o_numTemp = [];
                dados_e_thrTemp = [];
                dados_u_b_thrTemp = [];
                dados_e_h_estimateTemp = [];
                dados_p_e_h_estimateTemp = [];
            }
        }

        dadosTemp = Object.fromEntries(
            dadosTemp.map(({ data, total }) => [data, total])
        );

        dados2Temp = Object.fromEntries(
            dados2Temp.map(({ data, total }) => [data, total])
        );

        dadosMU = Object.fromEntries(
            dadosMU.map(({ data, total }) => [data, total])
        ); 

        dados3Temp = Object.fromEntries(
            dados3Temp.map(({ data, total }) => [data, total])
        );

        dados4Temp = Object.fromEntries(
            dados4Temp.map(({ data, total }) => [data, total])
        );

        dados_media_movelTemp = Object.fromEntries(
            dados_media_movelTemp.map(({ data, total }) => [data, total])
        );

        dados_variacao_media_movelTemp = Object.fromEntries(
            dados_variacao_media_movelTemp.map(({ data, total }) => [data, total])
        );

        dadosEVI = Object.fromEntries(
            dadosEVI.map(({ data, total }) => [data, total])
        );

        dadosEXC = Object.fromEntries(
            dadosEXC.map(({ data, total }) => [data, total])
        );

        dadosProbGroTrend = Object.fromEntries(
            dadosProbGroTrend.map(({ data, total }) => [data, total])
        );
        
        setDadosGraficoTotalAtendimentos(
            getDadosGraficoTotalAtendimentos(dadosTemp)
        );

        setDadosTotalAtendimentosGripais(dados2Temp);
        setDadosGraficoTotalAtendimentosGripais(
            getDadosGraficoTotalAtendimentosGripais(dados2Temp, dadosProbGroTrend)
        );

        setDadosGraficoRazaoAtendimentosGripais(
            //getDadosGraficoRazaoAtendimentosGripais(dadosEVI, dados3Temp)
            getDadosGraficoRazaoAtendimentosGripais(dados_media_movelTemp, dados3Temp)
        );

        setDadosGraficoTaxaMediaMovel(
            getDadosGraficoTaxaMediaMovel(dados_variacao_media_movelTemp)
        ); 

        setDadosGraficoTotalAtendimentosFull(
            getDadosGraficoTotalAtendimentos(dadosTemp, true)
        );

        setDadosGraficoTotalAtendimentosGripaisFull(
            getDadosGraficoTotalAtendimentosGripais(dados2Temp, dadosProbGroTrend, true)
        );

        setDadosGraficoRazaoAtendimentosGripaisFull(
            //getDadosGraficoRazaoAtendimentosGripais(dadosEVI, dados3Temp, true)
            getDadosGraficoRazaoAtendimentosGripais(dados_media_movelTemp, dados3Temp, true)
        );

        setDadosGraficoTaxaMediaMovelFull(
            getDadosGraficoTaxaMediaMovel(dados_variacao_media_movelTemp, true)
        );
        
        setDadosGraficoLimite(
            getDadosGraficoLimite(dados2Temp, dadosMU)
        )

        setDadosGraficoLimiteFull(
            getDadosGraficoLimite(dados2Temp, dadosMU, true)
        ) 

        setDadosGraficoExcesso(
            getDadosGraficoExcesso(dadosEXC)
        ) 
        setDadosGraficoExcessoFull(
            getDadosGraficoExcesso(dadosEXC, true)
        )

        //console.log("setLoading3");
        //setLoading(false);

    }, [dados_municipio_selecionado, indice]);

    useEffect(() => {
        if(dados_municipios === null)
            return;   

        let datasTemp = [];
        let semanasTemp = [];
        let inicioSemanasTemp = [];
        let fimSemanasTemp = [];
        
        let dados_totais_local = [];

        dados_municipios.forEach(function (atendimento) {
            var obj = {};
            datasTemp.push(atendimento["data"]);
            semanasTemp.push(atendimento["semana"]);
            inicioSemanasTemp.push(atendimento["data_inicial_semana"]);
            fimSemanasTemp.push(atendimento["data_final_semana"]);
            obj = {
                "date": atendimento["data"],
                "total_geral": atendimento["total_geral"],
                "total_gripal": atendimento["total_gripal"],
                "media_movel": atendimento["media_movel_ivas"],
                "variacao_media_movel": atendimento["variacao_media_movel"],
                "mu": indice.toLowerCase() === "ml" ? atendimento["lim_esp_ml_ivas"] : indice.toLowerCase() === "ens" ? atendimento["lim_esp_ens_ivas"] : indice.toLowerCase() === "evi" ? atendimento["lim_esp_evi_ivas"] : indice.toLowerCase() === "ears" ? atendimento["lim_esp_ears_ivas"] : atendimento["lim_esp_glm_ivas"],
                "exc": indice.toLowerCase() === "ml" ? fixaCasasDecimais(atendimento["exc_ml_ivas"], 2) : indice.toLowerCase() === "ens" ? fixaCasasDecimais(atendimento["exc_ens_ivas"], 2) : indice.toLowerCase() === "evi" ? fixaCasasDecimais(atendimento["exc_evi_ivas"], 2) : indice.toLowerCase() === "ears" ? fixaCasasDecimais(atendimento["exc_ears_ivas"], 2) : fixaCasasDecimais(atendimento["exc_glm_ivas"], 2),
                "exc_ambos": fixaCasasDecimais(atendimento["exc_ambos_ivas"], 2) ,
                "exc_evi": fixaCasasDecimais(atendimento["exc_evi_ivas"], 2) ,
                "exc_ears": fixaCasasDecimais(atendimento["exc_ears_ivas"], 2) ,
                "exc_ens": fixaCasasDecimais(atendimento["exc_ens_ivas"], 2) ,
                "exc_ml": fixaCasasDecimais(atendimento["exc_ml_ivas"], 2) ,
            };
            var busca = dados_totais_local.find(dt => dt["date"] === obj["date"]);

            if (busca != null) {
                teste_data = obj["date"];
                obj["total_geral"] = obj["total_geral"] + busca["total_geral"];
                obj["total_gripal"] = obj["total_gripal"] + busca["total_gripal"];
                obj["media_movel"] = obj["media_movel"] + busca["media_movel"];
                obj["variacao_media_movel"] = obj["variacao_media_movel"] + busca["variacao_media_movel"];
                obj["mu"] = obj["mu"] + busca["mu"];
                obj["exc"] = obj["exc"] + busca["exc"];

                obj["exc_ambos"] = obj["exc_ambos"] + busca["exc_ambos"];
                obj["exc_evi"] = obj["exc_evi"] + busca["exc_evi"];
                obj["exc_ears"] = obj["exc_ears"] + busca["exc_ears"];
                obj["exc_ens"] = obj["exc_ens"] + busca["exc_ens"];
                obj["exc_ml"] = obj["exc_ml"] + busca["exc_ml"];

                var idx = dados_totais_local.findIndex(checkDado);
                dados_totais_local[idx] = obj;
            } else {
                dados_totais_local.push(obj);
            }
        });

        //setDados_Totais(dados_totais_local);

        setDatas(datasTemp);

        setSemanas(semanasTemp);

        setInicioSemanas(inicioSemanasTemp);
        setFimSemanas(fimSemanasTemp);

        setMaxLengthData(datasTemp.length);

        /*setMinDate(datasTemp[0]);
        setMinIdxDate(0);
        setMaxDate(datasTemp[datasTemp.length - 1]);
        setMaxIdxDate(datasTemp.length - 1);*/

        //getDadosPorData(datasTemp[idxDataInicio], language, true);

        mudarData(datasTemp.length - 1, datasTemp);
        
       

    }, [dados_municipios]);

    useEffect(() => {
        if ((map.current === null))
            return;

        if (layerTile.current !== null) {
            map.current.removeLayer(layerTile.current);
        }

        var url = theme === "dark" ? "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}" : "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}";

        const defaultCenter = position;

        layerTile.current = L.tileLayer(url, {
            zoomControl: true,
            zoomSnap: zoomSnap_,
            zoomDelta: zoomSnap_,
            wheelPxPerZoomLevel: wheelPxPerZoomLevel_,
            defaultZoom: defaultZoom,
            maxZoom: 18,
            id: 'mapbox.streets'
        });
        /*map.current.setView(defaultCenter, defaultZoom);*/
        layerTile.current.addTo(map.current);

        corSelecao = (theme === "dark" ? "white" : "#757575");

        //resetShapeFile(estadoSelecionado, municipioSelecionado, false);

        if (posicao) {
            setTimeout(function () {
                window.scrollTo(0, posicao);
            }, 1);
        }

        if(!isAllSelect(estadoSelecionado) && isAllSelect(municipioSelecionado)){
            const keys = Object.keys(shapeFileBrasil.current["_layers"]);
            if (keys.length > 0) {
                    for (let index = 0; index < keys.length; index++) {
                        const key = keys[index];
                        const element = shapeFileBrasil.current["_layers"][key];
                        if(element !== undefined){
                            if(isAllSelect(estadoSelecionado)){
                                element.setStyle({ weight: weightNormal, color: transparenciaDefaultMapa/*theme === "dark" ? "white" : "#757575"*/, fillColor: /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/transparenciaDefaultMapa, fillOpacity: 0.5 });
                            } else 
                            if(estadoSelecionado === element["feature"]["properties"]["NM_UF"]){   
                                element.setStyle({ weight: 2, color: "black"/*transparenciaDefaultMapa*//*theme === "dark" ? "white" : "#757575"*/, fillColor: /*theme === "dark" ? cinzaDefaultMapa : cinzaDefaultMapaWhite*/transparenciaDefaultMapa, fillOpacity: 0.5 });
                            } else {
                                if(element.options.color === cinzaDefaultMapaBorda || element.options.color === cinzaDefaultMapaDark){
                                    element.setStyle({ weight: weightNormal, color: /*theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda*/"black", fillColor: /*transparenciaDefaultMapa*/"black", fillOpacity: transparencia.current });
                                }
                            }
                        
                        }
                } 
            }
        } else {
            const keys = Object.keys(shapeFileMunicipios.current["_layers"]);
            if (keys.length > 0) {
                for (let index = 0; index < keys.length; index++) {
                    const key = keys[index];
                    const elementLocal = shapeFileMunicipios.current["_layers"][key];
                    if (cod_ibge.toString() === getCodIbgeShapefile(shapeFileMunicipios.current, key)) {
                        elementLocal.setStyle({ weight: weightSelecao, color: /*corSelecao*/"black", fillOpacity: opacidadeMunicipio });
                        //map.current.fitBounds(elementLocal.getBounds().pad(1));
                    } else if(shapeFileMunicipios.current["_layers"][key]["options"]["fillColor"] === transparenciaDefaultMapa){
                        elementLocal.setStyle({ weight: weightNormal, color: theme === "dark" ? cinzaDefaultMapaDark : cinzaDefaultMapaBorda});
                    } else if(shapeFileMunicipios.current["_layers"][key]["options"]["fillColor"] === corMapa1){
                        elementLocal.setStyle({ weight: weightNormal, color: theme === "dark" ? corMapa1BordaDark : corMapa1Borda});
                    }
                    

                }
            }     
        }

        criarLegenda(0, 0, "ind", language);

    }, [theme]);

    useEffect(() => {
        if(isAllSelect(estadoSelecionado)){
            setEstadoSelecionado(t("dashboard.todos"));
        }

        estados_labels[0] = {label:t("dashboard.todos")};
        /*else
        {
            var codigo_estado = "";
            var siglaEstado = "";
            if(shapeFileBrasil.current !== null){
                Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
                    if(shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"] === estadoSelecionado){ 
                        siglaEstado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["SIGLA_UF"];
                        codigo_estado = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["CD_UF"];
                    } 
                });
    
    
                if(codigo_estado === 0){
                    setListaMunicipios(getCidades());
                }
                carregaDadosEstado(codigo_estado, siglaEstado);
            }
        } */

        if(isAllSelect(municipioSelecionado)){
            setListaMunicipios(getCidades());
            setMunicipioSelecionado(t("dashboard.todos"));
            let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
            setCidades_Labels(cidades_labels_tmp);
        }

        setListaEstados(getEstados());

        getDadosPorData(dataSelecionada, language, false);

        setCod_Ibge(cod_ibge);

        criarLegenda(0, 0, "ind", language);

        /*if(map.current.hasLayer(shapeFileEstado.current)){
            setNomeLayerAtiva(t("dashboard.titulo_mapa_evi"));
        } else if(map.current.hasLayer(shapeFileBrasilExcesso.current)) {
            setNomeLayerAtiva(t("dashboard.excesso"));
        }*/

        val_OTC = [
            t("dashboard.sem_aviso"),
            t("dashboard.aviso")
        ];

        if(selectedOTC.length === 2){
            setSelectedOTC(val_OTC);
        } else if (selectedOTC.length === 1){
            if(selectedOTC[0].includes("Sem") || selectedOTC[0].includes("No")){
                setSelectedOTC([t("dashboard.sem_aviso")]);
            } else {
                setSelectedOTC([t("dashboard.aviso")]);
            }
            //plotaMarkerOTC(listaMunicipios);
        }

        setlistaSinaisAviso(
            [
                {"sinal": "EVI", "nome":  t("dashboard.evi_simples")},
                {"sinal": "Ears", "nome": t("dashboard.ears")},
                {"sinal": "ML", "nome": "ML"},
                {"sinal": "GLM", "nome": "GLM"},
                {"sinal": "ENS", "nome": "ENSEMBLE"},
            ]);

        if(isAllSelect(filtroDQIMapa)){
            setFiltroDQIMapa(t("dashboard.ambos"));
        } else if(filtroDQIMapa.toLocaleLowerCase() === "fit" || filtroDQIMapa.toLocaleLowerCase() === "apto"){
            setFiltroDQIMapa(t("dashboard.apto"));
        } else if(filtroDQIMapa.toLocaleLowerCase() === "not fit" || filtroDQIMapa.toLocaleLowerCase() === "não apto"){
            setFiltroDQIMapa(t("dashboard.nao_apto"));
        }

        txtTotalAtendimentos.current = (t("dashboard.graficos.titulo_atendimento_totais"));
        txtTotalGripal.current = (t("dashboard.graficos.titulo_atendimento_gripais"));
        txtTaxaAtendimentos.current = (t("dashboard.graficos.legenda_mm.2"));
        txtNomeMunicipio.current = (t("dashboard.cidade"));
        txtNomeEstado.current = (t("dashboard.estado"));
        /*var overlays = {};

        overlays[t("dashboard.titulo_mapa_evi")] = shapeFileEstado.current;
        overlays[t("dashboard.excesso")] = shapeFileBrasil.current;
        
        if(map.current !== null && layerControl.current !== null)
            layerControl.current.remove();

        if(map.current !== null)
            layerControl.current = L.control.layers(null, overlays, { collapsed: false }).addTo(map.current);*/
        
        if (posicao) {
            setTimeout(function () {
                window.scrollTo(0, posicao);
            }, 1);
        }
    }, [language]);

    useEffect(() => {
        var maior_taxa = 0;
        var menor_taxa = 0;

        var tempMakers = [];
        var tempMakersMediaMovel = [];
        var tempCidadesTabela = [];

        if(shapeFileMunicipios.current === null ||  dados_municipios_por_data === null)
            return;

        let siglaEstado = "";
        /*Object.keys(shapeFileBrasil.current["_layers"]).forEach((shape) => {
            let siglaEstadoLocal = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["SIGLA_UF"];
            let nomeEstadoLocal = shapeFileBrasil.current["_layers"][shape]["feature"]["properties"]["NM_UF"];

            if(estadoSelecionado === nomeEstadoLocal){
                siglaEstado = siglaEstadoLocal;
            } 
        });*/

        const keys = Object.keys(shapeFileBrasil.current["_layers"]);
        if (keys.length > 0) {
            for (let index = 0; index < keys.length; index++) {
                const key = keys[index];
                const element = shapeFileBrasil.current["_layers"][key];
                if(element !== undefined){
                    let siglaEstadoLocal = element["feature"]["properties"]["SIGLA_UF"];
                    let nomeEstadoLocal = element["feature"]["properties"]["NM_UF"];

                    if(estadoSelecionado === nomeEstadoLocal){
                        siglaEstado = siglaEstadoLocal;
                        break;
                    } 
                } else {
                    break;
                }
            }
        } 

        let codigo_estado = getCodEstadoBySigla(siglaEstado);

        if(dados_municipios_por_data !== null){
            for(let x = 0; x < dados_municipios_por_data["municipios"].length; x++){       
                /*tempMakersMediaMovel.push(
                    criaTempMakersMediaMovelMunicipal(dados_municipios_por_data["municipios"][x],  dados_municipios_por_data['data'], dados_municipios_por_data['semana'])
                    );*/
                if(!isAllSelect(estadoSelecionado) && dados_municipios_por_data["municipios"][x]["cod_ibge"].toString().startsWith(codigo_estado)){
                    tempCidadesTabela.push(
                        criaTempMakersMediaMovelMunicipal(dados_municipios_por_data["municipios"][x], dados_municipios_por_data['data'], dados_municipios_por_data['semana'])
                        );
                }
            }  
             
            if(!isAllSelect(estadoSelecionado)){
                setMarkers(tempCidadesTabela);
            }
            /*setMarkers(!isAllSelect(estadoSelecionado) ? tempCidadesTabela : tempMakersMediaMovel);*/
        }
        
        plotaMapa(dados_municipios_por_data["municipios"], "useEffect 1");

        criarLegenda(0, 0, "ind", language);

        plotaMarkerOTC(listaMunicipios);

    }, [dados_municipios_por_data, exibeShapeFileVerde, exibeShapeFileAmarelo, exibeShapeFileVermelho, filtroDQIMapa, selectedVal_Prob_Gro_Trend, indice, selectedOTC]);

    useEffect(() => {
        if (filtrandoMunicipio || dataSelecionada === 0 || !iniciou.current)
            return;

        setFiltrandoMunicipio(true);
        //resetShapeFile();

        if (isAllSelect(estadoSelecionado) && isAllSelect(municipioSelecionado)) {

            //setPopulacao("0");
            //console.log("0");

            let codigo_estado = 0;

            carregaDadosEstado(codigo_estado);
            setMunicipioSelecionado(t("dashboard.todos"));
        } else if (!isAllSelect(municipioSelecionado)) {
            var encontrou = false;
            var encontrouSesab = false;
            getMunicipioTodoPeriodo(cod_ibge, true);
        } 

        setFiltrandoMunicipio(false);
        carregandoEstado.current = false;
        
    }, [cod_ibge, dataSelecionada, dados_totais]);

    useEffect(() => {
        if(isAllSelect(estadoSelecionado))
        {
            if(listaEstados.length > 0){
                setEstadoSelecionado(t("dashboard.todos")); 
                setListaMunicipios(getCidades());
                let cidades_labels_tmp = [{cod_ibge: t("dashboard.todos"), label: t("dashboard.todos")}];
                setCidades_Labels(cidades_labels_tmp);
            }
        } 
    }, [listaEstados]);

    function cleanTableData (table) {
        // Obtém todas as células da tabela
        const cells = table.querySelectorAll('td');
    
        // Itera sobre cada célula e remove caracteres indesejados
        cells.forEach((cell) => {
          // Substitua 'caractere-indesejado' pelo caractere que você deseja remover
          const cleanedText = cell.textContent.replace(/↑/g, '');
    
          // Atualiza o conteúdo da célula com o texto limpo
          cell.textContent = cleanedText;
        });
    };

    function exportToExcel() {
        const htmlTable = document.getElementById('dtBasicExampleExport');
        const tableCopy = htmlTable.cloneNode(true);

        // Modifique a cópia da tabela para remover caracteres indesejados
        cleanTableData(tableCopy);

        var nomeArquivo = dataSelecionada.replace(" ", "");
        nomeArquivo = nomeArquivo.replace("-", "_");
        nomeArquivo = nomeArquivo.replace("-", "_");
        nomeArquivo = nomeArquivo.replace("00:00:00", "");

        const wb = XLSX.utils.table_to_book(tableCopy);
        XLSX.writeFile(wb, 'tabela_'+nomeArquivo+'.xlsx');
    }

    function exportToCSV() {
        const htmlTable = document.getElementById('dtBasicExampleExport');
        const rows = htmlTable.querySelectorAll('tr');

        let csv = '';
        for (const row of rows) {
            const cells = row.querySelectorAll('th,td');
            const rowData = Array.from(cells).map(cell => cell.textContent.replace('↑', ''));
            csv += rowData.join(',') + '\n';
        }

        var nomeArquivo = dataSelecionada.replace(" ", "");
        nomeArquivo = nomeArquivo.replace("-", "_");
        nomeArquivo = nomeArquivo.replace("-", "_");
        nomeArquivo = nomeArquivo.replace("00:00:00", "");

        const blob = new Blob([csv], { type: 'text/csv;charset=iso-8859-1;'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'tabela_'+nomeArquivo+'.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    function getDescricaoIndice(){
        var descricaoIndice = "";

        if(indice.toLowerCase() === "ml"){
            descricaoIndice = t("dashboard.table.ml");
        } else if(indice.toLowerCase() === "ens"){
            descricaoIndice = t("dashboard.table.ens");
        } else if(indice.toLowerCase() === "evi") {
            descricaoIndice = t("dashboard.evi_simples");
        } else if(indice.toLowerCase() === "glm") {
            descricaoIndice = t("dashboard.table.glm");
        } else {
            descricaoIndice = t("dashboard.ears");
        }  

        return descricaoIndice;
    }

    const rectangleCount = 10; // Número de retângulos
    const redTones = [
        "#990000",
        "#CC0000",
        "#FF0000",
        "#FF3333",
        "#FF6666",
        "#FF9999",
        "#FFCCCC",
        "#FF9999",
        "#FF6666",
        "#FF3333"
    ];

    const rectangles = [];
    for (let i = 0; i < rectangleCount; i++) {
        const style = {
            backgroundColor: redTones[i],
            maxHeight: "5px",
            minHeight: "2px",
            maxWidth: "10%",
            minWidth: "10%",
            flex: "1"
        };
        rectangles.push(<div key={i} style={style}></div>);
    }


  return (
    <Container className="container_Mapa">
        {loading && <div className={style.loading}><div className={style.lds_ring}><div></div><div></div><div></div><div></div></div></div>}

        <TabNav theme={theme} language={language} currentPage={"Painel Resumido"} link_gripe="" link_zika= {link_zika} />
        {/*<div className='nav_abas' style={{backgroundColor: theme === "dark" ? "black" : "white"}}>
            <div class="menu">
                <button href="#" class="menu-button"><Translator path="dashboard.gripe" /> <img class="arrow" src="imagens/seta-para-expandir.png"/></button>
                <div class="submenu">
                    <a href="/maparesumido"><Translator path="dashboard.title_resumo" /></a>
                    <a href="/metagenomic"><Translator path="dashboard.title_metagenomic" /></a>
                    <a href="/ibp">IBP</a>
                    <a href="/climate"><Translator path="dashboard.title_climate" /></a>
                    <a href="/webbased">Web Based</a> 
                </div> 
            </div>   
            <div class="menu2">
                <button href={link_zika} class="menu-button2" style={{backgroundColor: theme === "dark" ? "#363636" : "#d6d6d6"}}><Translator path="dashboard.arbo" /> <img class="arrow ocultar" src="imagens/seta-para-expandir.png"/></button>
                
            </div>
            
            <div style={{ minHeight: "5px", minWidth:"100%", display:"flex"}}>{rectangles}</div>  
        </div>   */}     
        <Row>
                    <Row>
                        <div className={style.div_top}>
                            <br></br>
                            {/*<Translator path="dashboard.data" />: <Moment date={dataSelecionada} format='DD/MM/YYYY'></Moment>*/}
                            <div className='titulo_semana_ep'>
                                <Translator path="dashboard.semana_ep" />: {FormatarDoisDigitos(semanaSelecionada) + "-" + extraiAno(dataSelecionada)} 
                            </div>
                            <div className={style.lado_a_lado}>
                                <a href="#" className={`${style.previous} ${style.round}`} onClick={() => { mudarData((parseInt(idxDataSelecionada) - 1), datas) }}>&#8249;</a>
                                <Box sx={{ width: "150px", paddingTop:"0px", marginTop:"0px" }}>
                                    <Slider 
                                        id="sel_data"
                                        value={idxDataSelecionada} 
                                        max={maxLengthData} 
                                        min={0} 
                                        aria-label="Default" 
                                        valueLabelDisplay="off" 
                                        onChange={handleChangeSelectData}
                                    />
                                </Box>
                                <a href="#" className={`${style.next} ${style.round}`} onClick={() => { mudarData((parseInt(idxDataSelecionada) + 1), datas) }}>&#8250;</a>
                                <div className='dataSemana'>
                                    <Moment date={inicioSemanaSelecionada} format='DD/MM/YYYY'></Moment>&nbsp;<Translator path="dashboard.a"/>&nbsp;<Moment date={fimSemanaSelecionada} format='DD/MM/YYYY'></Moment>
                                </div>
                            </div>
                            <div className={style.container_seleciona_municipio_e_cor}>
                                <div className={style.seleciona_municipio}> 
                                    <Autocomplete
                                        {...defaultProps}
                                        disableClearable
                                        id="autocomplete_estado"
                                        className='change_color_text'
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        value={{label:estadoSelecionado}}
                                        onChange={(event, newValue) => {
                                            setLoading(true);
                                            setEstadoSelecionado(newValue.label);
                                            setCod_Ibge(t("dashboard.todos"));
                                            setMunicipioSelecionado(t("dashboard.todos"));
                                        }}
                                        renderInput={(params) => (
                                            <TextField className='autocomplete_estado_textfield' {...params} label={t("dashboard.estado")} size="small"/>
                                        )}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    
                                    <Autocomplete
                                        {...defaultPropsMunicipio}
                                        disableClearable
                                        id="autocomplete_municipio"
                                        className='change_color_text'
                                        isOptionEqualToValue={(option, value) => option.cod_ibge === value.cod_ibge}
                                        value={{cod_ibge:cod_ibge, label:municipioSelecionado}}
                                        onChange={(event, newValue) => {
                                            handleChangeMunicipioNome(newValue.cod_ibge);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={t("dashboard.cidade")} size="small"/>
                                        )}
                                    />

                                </div>
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <div className={style.mapa}>
                                <div id="map" className={`${style.normalscreen} ${style.map}`}>
     
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className={`grafico ${style.grafico}`}>
                                <Button className={`button_maximize ${style.button_maximize} ${style.float_right}`} onClick={() => setModal3Show(true)}></Button>
                                <Line onClick={() => setModal3Show(true)} options={getOptions(theme, t("dashboard.graficos.titulo_atendimento_gripais"))} data={dadosGraficoTotalAtendimentosGripais} />
                            </div>
                        </Col>
                        <Col md={12} className={style.retangulos}>
                            <Retangulo cor = "#b5b5b5" nome = {t("dashboard.table.sinais")} valor={totalSinais} perc={false} abrevia={true} t={t} valor_diferenca={totalSinais - totalSinaisAnt}></Retangulo>                                 
                            <Retangulo cor = {corAtendimentosGripais} nome = {t("dashboard.graficos.titulo_atendimento_gripais")} valor={numAtendimentosGripais} perc={false} abrevia={true} t={t} valor_diferenca={numAtendimentosGripais-numAtendimentosGripaisAnterior}></Retangulo>
                            <Retangulo cor = {corMediaMovel} nome = {t("dashboard.graficos.titulo_atendimento_totais")} valor={atendimentoTotaisRetangulo} perc={false} abrevia={true} t={t} valor_diferenca={atendimentoTotaisRetangulo-atendimentoTotaisRetanguloAnterior}></Retangulo>
                        </Col>
                    </Row>
                </Row>
                <Row>
                    <div className={style.table_container}>
                        <div className={style.div_top}>
                            {/*<Translator path="dashboard.data" />: <Moment date={dataSelecionada} format='DD/MM/YYYY'></Moment>*/}
                            <div className='titulo_semana_ep'>
                                <Translator path="dashboard.semana_ep" />: {FormatarDoisDigitos(semanaSelecionada) + "-" + extraiAno(dataSelecionada)} 
                            </div>
                            <div className={style.lado_a_lado}>
                                <a href="#" className={`${style.previous} ${style.round}`} onClick={() => { mudarData((parseInt(idxDataSelecionada) - 1), datas) }}>&#8249;</a>
                                <Box sx={{ width: "150px", paddingTop:"0px", marginTop:"0px" }}>
                                    <Slider 
                                        id="sel_data"
                                        value={idxDataSelecionada} 
                                        max={maxLengthData} 
                                        min={0} 
                                        aria-label="Default" 
                                        valueLabelDisplay="off" 
                                        onChange={handleChangeSelectData}
                                    />
                                </Box>
                                <a href="#" className={`${style.next} ${style.round}`} onClick={() => { mudarData((parseInt(idxDataSelecionada) + 1), datas) }}>&#8250;</a>
                                <div className='dataSemana'>
                                    <Moment date={inicioSemanaSelecionada} format='DD/MM/YYYY'></Moment>&nbsp;<Translator path="dashboard.a"/>&nbsp;<Moment date={fimSemanaSelecionada} format='DD/MM/YYYY'></Moment>
                                </div>
                            </div>
                            <div className={style.container_seleciona_municipio_e_cor}>
                                <div className={style.seleciona_municipio}> 
                                    {/*<FormControl id="controle-selecao">
                                        <InputLabel id="demo-simple-select-label">{t("dashboard.estado")}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={estadoSelecionado === null ? t("dashboard.todos") : estadoSelecionado }
                                            onChange={handleChangeEstado}
                                            label={t("dashboard.estado")}
                                            name={"estados"}
                                            sx={{ width: 170, height: 30}}
                                        >
                                        {listaEstados.map((e, key) => {
                                            return (
                                                <MenuItem key={key} value={e["ESTADO"]}>{e["ESTADO"]}</MenuItem>
                                            );
                                        })}
                                        </Select>
                                    </FormControl> */}
                                    <Autocomplete
                                        {...defaultProps}
                                        disableClearable
                                        id="autocomplete_estado"
                                        className='change_color_text'
                                        isOptionEqualToValue={(option, value) => option.label === value.label}
                                        value={{label:estadoSelecionado}}
                                        onChange={(event, newValue) => {
                                            setLoading(true);
                                            setEstadoSelecionado(newValue.label);
                                            setCod_Ibge(t("dashboard.todos"));
                                            setMunicipioSelecionado(t("dashboard.todos"));
                                        }}
                                        renderInput={(params) => (
                                            <TextField className='autocomplete_estado_textfield' {...params} label={t("dashboard.estado")} size="small" />
                                        )}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    
                                    <Autocomplete
                                        {...defaultPropsMunicipio}
                                        disableClearable
                                        id="autocomplete_municipio"
                                        className='change_color_text'
                                        isOptionEqualToValue={(option, value) => option.cod_ibge === value.cod_ibge}
                                        value={{cod_ibge:cod_ibge, label:municipioSelecionado}}
                                        onChange={(event, newValue) => {
                                            handleChangeMunicipioNome(newValue.cod_ibge);
                                        }}
                                        renderInput={(params) => (
                                            <TextField {...params} label={t("dashboard.cidade")} size="small"/>
                                        )}
                                    />

                                    {/*
                                    
                                    <FormControl id="controle-selecao-indicador">
                                        <InputLabel id="demo-simple-select-label">{t("dashboard.titulo_mapa_evi")}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={indice}
                                            onChange={handleChangeIndicador}
                                            label={t("dashboard.titulo_mapa_evi")}
                                            name={"sinais_aviso"}
                                            sx={{ width: 120, height: 32}}
                                        >
                                        {listaSinaisAviso.map((e, key) => {
                                            return (
                                                <MenuItem key={key} value={e["sinal"]}>{e["nome"]}</MenuItem>
                                            );
                                        })}
                                        </Select>
                                    </FormControl> 
                                    <FormControl id="controle-selecao">
                                        <InputLabel id="demo-simple-select-label">DQI</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filtroDQIMapa}
                                            label="DQI"
                                            onChange={handleChangeDQIFiltroMapa}
                                            sx={{ width: 100, height: 32}}
                                        >
                                        <MenuItem value={t("dashboard.todos")}>{t("dashboard.todos")}</MenuItem>
                                        <MenuItem value={t("dashboard.apto")}>{t("dashboard.aptos")}</MenuItem>
                                        <MenuItem value={t("dashboard.nao_apto")}>{t("dashboard.nao_apto")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                    
                                    <FormControl id="multipleselect_form" >
                                        <InputLabel>{t("dashboard.probabilidade_crescimento")}</InputLabel>
                                        <Select
                                            id="multipleselect_select"
                                            multiple
                                            value={selectedVal_Prob_Gro_Trend}
                                            onChange={(e) => setSelectedVal_Prob_Gro_Trend(e.target.value)}
                                            input={<OutlinedInput label={t("dashboard.probabilidade_crescimento")} />}
                                            renderValue={(selected) => (
                                                <Stack id="multipleselect_stack" gap={1} direction="row" flexWrap="wrap">
                                                    {selected.map((value) => (
                                                    <Chip
                                                        id="multipleselect_chip"
                                                        key={value}
                                                        label={value}
                                                        sx={theme === "dark" ? { color: "white", backgroundColor: "#2f3136" } : {  }}
                                                        onDelete={() => {
                                                                if(selectedVal_Prob_Gro_Trend.length > 1)
                                                                setSelectedVal_Prob_Gro_Trend(
                                                                    selectedVal_Prob_Gro_Trend.filter((item) => item !== value)
                                                                )
                                                            }
                                                        }
                                                        deleteIcon={
                                                        <CancelIcon
                                                            id="multipleselect_cancelicon"
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                        }
                                                    />
                                                    ))}
                                                </Stack>
                                            )}
                                            
                                        >
                                            {val_prob_gro_trend.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                sx={{ justifyContent: "space-between"}}
                                            >
                                                {name}
                                                {selectedVal_Prob_Gro_Trend.includes(name) ? <CheckIcon id="multipleselect_checkicon" color="info" /> : null}
                                            </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    */}
                                    <div className='divLimpaFiltros'>
                                        <img className="limpaFiltros" src='/imagens/limpar-filtros.png' onClick={limpaFiltros}></img>
                                        <div className="balaoDeTextoLimparFiltros noHover">Limpar Filtros</div>
                                    </div>
                                    <div className='divExportPlanilhaCSV'>
                                        <img className="exportPlanilhaCSV" src='/imagens/csv_logo.gif' onClick={exportToCSV}></img>
                                        <div className="balaoDeTextoCsv noHover">Download CSV</div>
                                    </div>
                                    <div className='divExportPlanilhaExcel'>
                                        <img className="exportPlanilha" src='/imagens/excel_logo.gif' onClick={exportToExcel}></img>
                                        <div className="balaoDeTextoExcel noHover">Download XLSX</div>        
                                    </div>
                                </div>
                            </div>
                            
                        </div>

                        
                        {/*<div className={style.space_top}>  
                                
                                
                                <Translator path="dashboard.semana_ep" />: {FormatarDoisDigitos(semanaSelecionada)}
                                <br></br>
                                <div className={style.slider_div}>
                                    <a href="#" className={`${style.previous} ${style.round}`} onClick={() => { mudarData(parseInt(idxDataSelecionada) - 1, datas) }}>&#8249;</a>
                                    <Box sx={{ width: "150px", paddingTop:"0px", marginTop:"0px" }}>
                                        <Slider 
                                            id="sel_data"
                                            value={idxDataSelecionada} 
                                            max={datas != null ? datas.length - 1 : 0} 
                                            min={0} 
                                            aria-label="Default" 
                                            valueLabelDisplay="off" 
                                            onChange={handleChangeSelectData}
                                        />
                                    </Box>
                                    <a href="#" className={`${style.next} ${style.round}`} onClick={() => { mudarData(parseInt(idxDataSelecionada) + 1, datas) }}>&#8250;</a>
                                    <div className='dataSemana'>
                                        <Moment date={inicioSemanaSelecionada} format='DD/MM/YYYY'></Moment>&nbsp;<Translator path="dashboard.a"/>&nbsp;<Moment date={fimSemanaSelecionada} format='DD/MM/YYYY'></Moment>
                                    </div> 
                                    <div id="divBuscaMunicipioText">
                                        <Autocomplete
                                            {...defaultProps}
                                            disableClearable
                                            id='autocomplete_estado_table'
                                            className="autocomplete_estado_table change_color_text"
                                            isOptionEqualToValue={(option, value) => option.label === value.label}
                                            value={{label:estadoSelecionado}}
                                            onChange={(event, newValue) => {
                                                setEstadoSelecionado(newValue.label);
                                            }}
                                            renderInput={(params) => (
                                                <TextField className='autocomplete_estado_textfield' {...params} label={t("dashboard.estado")} size="small"/>
                                            )}
                                        />

                                        <Autocomplete
                                            {...defaultPropsMunicipio}
                                            disableClearable
                                            id="autocomplete_municipio_table"
                                            className='change_color_text'
                                            isOptionEqualToValue={(option, value) => option.label === value.label}
                                            value={{label:municipioSelecionado}}
                                            onChange={(event, newValue) => {
                                                handleChangeMunicipioNome(newValue.label);
                                            }}
                                            renderInput={(params) => (
                                                <TextField className='autocomplete_municipio_textfield' {...params} label={t("dashboard.cidade")} size="small"/>
                                            )}
                                        />
                                        
                                        <FormControl id="filtraDQITabela">
                                            <InputLabel id="demo-simple-select-label">DQI</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={filtroDQIMapa}
                                                label="DQI"
                                                onChange={handleChangeDQIFiltroMapa}
                                            >
                                                <MenuItem value={t("dashboard.todos")}>{t("dashboard.todos")}</MenuItem>
                                                <MenuItem value={t("dashboard.apto")}>{t("dashboard.apto")}</MenuItem>
                                                <MenuItem value={t("dashboard.nao_apto")}>{t("dashboard.nao_apto")}</MenuItem>
                                                
                                            </Select>
                                        </FormControl>
                                        
                                        <FormControl id="multipleselect_form_table" >
                                            <InputLabel>{t("dashboard.probabilidade_crescimento")}</InputLabel>
                                            <Select
                                                id="multipleselect_select"
                                                multiple
                                                value={selectedVal_Prob_Gro_Trend}
                                                onChange={(e) => setSelectedVal_Prob_Gro_Trend(e.target.value)}
                                                input={<OutlinedInput label={t("dashboard.probabilidade_crescimento")} />}
                                                renderValue={(selected) => (
                                                <Stack id="multipleselect_stack_table" gap={1} direction="row" flexWrap="wrap">
                                                    {selected.map((value) => (
                                                    <Chip
                                                        id="multipleselect_chip_table"
                                                        key={value}
                                                        label={value}
                                                        sx={theme === "dark" ? { color: "white", backgroundColor: "#2f3136" } : {  }}
                                                        onDelete={() => {
                                                                if(selectedVal_Prob_Gro_Trend.length > 1)
                                                                setSelectedVal_Prob_Gro_Trend(
                                                                    selectedVal_Prob_Gro_Trend.filter((item) => item !== value)
                                                                )
                                                            }
                                                        }
                                                        deleteIcon={
                                                        <CancelIcon
                                                            id="multipleselect_cancelicon"
                                                            onMouseDown={(event) => event.stopPropagation()}
                                                        />
                                                        }
                                                    />
                                                    ))}
                                                </Stack>
                                                )}
                                            >
                                                {val_prob_gro_trend.map((name) => (
                                                <MenuItem
                                                    key={name}
                                                    value={name}
                                                    sx={{ justifyContent: "space-between" }}
                                                >
                                                    {name}
                                                    {selectedVal_Prob_Gro_Trend.includes(name) ? <CheckIcon id="multipleselect_checkicon" color="info" /> : null}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className='divExportPlanilhaCSV'>
                                    <img className="exportPlanilhaCSV" src='/imagens/csv_logo.gif' onClick={exportToCSV}></img>
                                    <div className="balaoDeTextoCsv noHover">Download CSV</div>
                                </div>
                                <div className='divExportPlanilhaExcel'>
                                    <img className="exportPlanilha" src='/imagens/excel_logo.gif' onClick={exportToExcel}></img>
                                    <div className="balaoDeTextoExcel noHover">Download XLSX</div>        
                                </div>
                        </div> */}

                        <Tabela 
                            indice= {indice}
                            estadoSelecionado={estadoSelecionado}
                            t={t}
                            theme = {theme} 
                            campoOrdem={campoOrdem} 
                            setCampoOrdem= {setCampoOrdem} 
                            decrescente= {decrescente} 
                            setDecrescente= {setDecrescente} 
                            handleChangeEstadoTabela = {handleChangeEstadoTabela} 
                            handleChangeMunicipioTabela = {handleChangeMunicipioTabela} 
                            tabelaOrdenada = {tabelaOrdenada}
                            exportarDados={false}
                            versaoResumida={true}
                            >
                        </Tabela>
                        <Tabela 
                            indice= {indice}
                            estadoSelecionado={estadoSelecionado}
                            t={t}
                            theme = {theme} 
                            campoOrdem={campoOrdem} 
                            setCampoOrdem= {setCampoOrdem} 
                            decrescente= {decrescente} 
                            setDecrescente= {setDecrescente} 
                            handleChangeEstadoTabela = {handleChangeEstadoTabela} 
                            handleChangeMunicipioTabela = {handleChangeMunicipioTabela} 
                            tabelaOrdenada = {tabelaOrdenada}
                            exportarDados={true}
                            versaoResumida={true}
                            >
                        </Tabela>

                        {/*    
                        <div className={style.table}>
                            <div className={style.titulo_tabela}><h5><center>{!isAllSelect(estadoSelecionado) ? t("dashboard.table.titulo") : t("dashboard.table.titulo_estados")}</center></h5></div>
                            <Table id="dtBasicExample" striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                                <thead>
                                    <tr>
                                        <th><a href='#' style={{ color: campoOrdem === "estado" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "estado") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("estado")
                                        }}>
                                        <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "municipio" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "municipio") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("municipio")
                                            }}>
                                            <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "total_gripal" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "total_gripal") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("total_gripal")
                                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col7"} />  </a></th>
                                        <th><a href='#' style={{ color: campoOrdem === "total_geral" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "total_geral") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("total_geral")
                                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col8"} />  </a></th>
                                        

                                        <th><a href='#' style={{ color: campoOrdem === "dqi" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "dqi") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("dqi")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.dqi"} /> </a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "perc_completude" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "perc_completude") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("perc_completude")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.perc_completude"} /> </a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "diff_2w" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "diff_2w") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("diff_2w")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.perc_tempestividade"} /> </a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "variacao_media_movel" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "variacao_media_movel") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("variacao_media_movel")
                                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col3"} />  </a>
                                        </th>

                                        <th>
                                            <a href='#' style={{ color: campoOrdem === "prob_gro_trend_ivas" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "prob_gro_trend_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("prob_gro_trend_ivas")
                                            }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.probabilidade_crescimento"} /> %  </a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "sinal_evi_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "sinal_evi_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("sinal_evi_consec_ivas")
                                        }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_evi"} /> </a>
                                        </th>
                                        <th><a href='#' style={{ color: campoOrdem === "excesso_evi_ivas" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "excesso_evi_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("excesso_evi_ivas")
                                        }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} /> <Translator path={"dashboard.table.evi"} />  </a>
                                        </th>
                                        <th>
                                            <a href='#' style={{ color: campoOrdem === "limite_superior_evi_ivas" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "limite_superior_evi_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("limite_superior_evi_ivas")
                                                }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />   <Translator path={"dashboard.table.evi"} />
                                            </a>
                                        </th>
                                        <th><a href='#' style={{ color: campoOrdem === "sinal_ears_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "sinal_ears_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("sinal_ears_consec_ivas")
                                        }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ears"} />  </a>
                                        </th>
                                        <th><a href='#' style={{ color: campoOrdem === "excesso_ears_ivas" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "excesso_ears_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("excesso_ears_ivas")
                                        }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} />  <Translator path={"dashboard.table.ears"} />  </a>
                                        </th>
                                        <th>
                                            <a href='#' style={{ color: campoOrdem === "limite_superior_ears_ivas" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "limite_superior_ears_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("limite_superior_ears_ivas")
                                                }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />  <Translator path={"dashboard.table.ears"} />  
                                            </a>
                                        </th>         
                                        <th>
                                            <a href='#' style={{ color: campoOrdem === "limite_superior_ambos_ivas" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "limite_superior_ambos_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("limite_superior_ambos_ivas")
                                                }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ambos"} />  
                                            </a>
                                        </th>  

                                    </tr>
                                </thead>
                                <tbody>
                                    {tabelaOrdenada().map((value, index, array) => {
                                        const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                                        //console.log(value);
                                        return (
                                            <tr key={index}>
                                                <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado"]) }}>{ value["uf"]}</a></td>
                                                <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge"]) }}>{value["municipio"]}</a></td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["total_gripal"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["total_geral"]))}</td>
                                                
                                                
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["dqi"] === "Apto" ? t("dashboard.apto") : t("dashboard.nao_apto")}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["perc_completude"]),2)}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["diff_2w"]),2)}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["variacao_media_movel"] > 15 ? "↑" + ' ' : "") + fixaCasasDecimais(trataNaNeNullInt(value["variacao_media_movel"]), 2)}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["prob_gro_trend_ivas"] === null ? valorNil :fixaCasasDecimais(trataNaNeNull(value["prob_gro_trend_ivas"] * 100),2)}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_evi_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_evi_consec_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["excesso_evi_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_evi_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNullInt(value["limite_superior_evi_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_ears_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ears_consec_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["excesso_ears_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_ears_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNullInt(value["limite_superior_ears_ivas"]))}</td>
                                                
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_ambos_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ambos_consec_ivas"]))}</td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            
                        </div>
                       
                        <div className="ocultar">
                        <div className={style.titulo_tabela}><h5><center>{!isAllSelect(estadoSelecionado) ? t("dashboard.table.titulo") : t("dashboard.table.titulo_estados")}</center></h5></div>
                        
                            <Table id="dtBasicExampleExport"  striped bordered hover variant={theme === "dark" ? "dark" : ""}>
                                <thead>
                                    <tr>
                                        <th><a href='#' style={{ color: campoOrdem === "estado" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "estado") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("estado")
                                        }}>
                                        <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.estado"} /></a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "municipio" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "municipio") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("municipio")
                                            }}>
                                            <img className={theme === "dark" ? '' : style.invert} src="/imagens/icones/setas-ordenadas.png" ></img> <Translator path={"dashboard.table.col1"} /></a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "total_gripal" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "total_gripal") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("total_gripal")
                                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col7"} />  </a></th>
                                        <th><a href='#' style={{ color: campoOrdem === "total_geral" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "total_geral") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("total_geral")
                                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col8"} />  </a></th>
                                        

                                        <th><a href='#' style={{ color: campoOrdem === "dqi" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "dqi") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("dqi")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.dqi"} /> </a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "perc_completude" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "perc_completude") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("perc_completude")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.perc_completude"} /> </a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "diff_2w" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "diff_2w") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("diff_2w")
                                        }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.perc_tempestividade"} /> </a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "variacao_media_movel" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "variacao_media_movel") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("variacao_media_movel")
                                        }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.col3"} />  </a>
                                        </th>

                                        <th>
                                            <a href='#' style={{ color: campoOrdem === "prob_gro_trend_ivas" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "prob_gro_trend_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("prob_gro_trend_ivas")
                                            }}> <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.probabilidade_crescimento"} /> %  </a>
                                        </th>

                                        <th><a href='#' style={{ color: campoOrdem === "sinal_evi_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "sinal_evi_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("sinal_evi_consec_ivas")
                                        }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_evi"} /> </a>
                                        </th>
                                        <th><a href='#' style={{ color: campoOrdem === "excesso_evi_ivas" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "excesso_evi_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("excesso_evi_ivas")
                                        }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} /> <Translator path={"dashboard.table.evi"} />  </a>
                                        </th>
                                        <th>
                                            <a href='#' style={{ color: campoOrdem === "limite_superior_evi_ivas" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "limite_superior_evi_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("limite_superior_evi_ivas")
                                                }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />   <Translator path={"dashboard.table.evi"} />
                                            </a>
                                        </th>
                                        <th><a href='#' style={{ color: campoOrdem === "sinal_ears_consec_ivas" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "sinal_ears_consec_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("sinal_ears_consec_ivas")
                                        }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.sinal_ears"} />  </a>
                                        </th>
                                        <th><a href='#' style={{ color: campoOrdem === "excesso_ears_ivas" ? "cadetblue" : ""}} onClick={() => {
                                            if (campoOrdem === "excesso_ears_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                            setCampoOrdem("excesso_ears_ivas")
                                        }}>  <img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.excesso"} />  <Translator path={"dashboard.table.ears"} />  </a>
                                        </th>
                                        <th>
                                            <a href='#' style={{ color: campoOrdem === "limite_superior_ears_ivas" ? "cadetblue" : ""}} onClick={() => {
                                                if (campoOrdem === "limite_superior_ears_ivas") { setDecrescente(!decrescente); } else { setDecrescente(false); }
                                                setCampoOrdem("limite_superior_ears_ivas")
                                                }}><img className={theme === "dark" ? '' : style.invert} src='/imagens/icones/setas-ordenadas.png'></img> <Translator path={"dashboard.table.limite"} />  <Translator path={"dashboard.table.ears"} />  
                                            </a>
                                        </th>         

                                    </tr>
                                </thead>
                                <tbody>
                                    {tabelaOrdenada().map((value, index, array) => {
                                        const isMesmoEstado = index < array.length - 1 && value["cod_uf"] === array[index + 1]["cod_uf"];
                                        //console.log(value);
                                        return (
                                            <tr key={index}>
                                                <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeEstadoTabela(value["estado"]) }}>{value["uf"]}</a></td>
                                                <td className={style.linha_tabela}><a href='#' onClick={() => {handleChangeMunicipioTabela(value["cod_ibge"]) }}>{value["municipio"]}</a></td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["total_gripal"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNull(value["total_geral"]))}</td>
                                                
                                                
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["dqi"] === "Apto" ? t("dashboard.apto") : t("dashboard.nao_apto")}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["perc_completude"]),2)}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{fixaCasasDecimais(trataNaNeNull(value["diff_2w"]),2)}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["variacao_media_movel"] > 15 ? "↑" + ' ' : "") + fixaCasasDecimais(trataNaNeNullInt(value["variacao_media_movel"]), 2)}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{value["prob_gro_trend_ivas"] === null ? valorNil :fixaCasasDecimais(trataNaNeNull(value["prob_gro_trend_ivas"] * 100),2)}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_evi_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_evi_consec_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["excesso_evi_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_evi_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNullInt(value["limite_superior_evi_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["sinal_ears_consec_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["sinal_ears_consec_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{(value["excesso_ears_ivas"] > 0 ? ("↑" + '  ') : "") + preparaInt(trataNaNeNullInt(value["excesso_ears_ivas"]))}</td>
                                                <td className={`${style.linha_tabela} ${style.numero_tabela}`}>{preparaInt(trataNaNeNullInt(value["limite_superior_ears_ivas"]))}</td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </div>
                        */} 
                    </div>
                </Row>

                <PopupGrafico
                    show={modal3Show}
                    onHide={() => setModal3Show(false)}
                    options={getOptions(theme, t("dashboard.graficos.titulo_atendimento_gripais"))}
                    data={dadosGraficoTotalAtendimentosGripais}
                    type="Bar"
                    titulo={""}
                    linguagem={language}
                                />
                

                <Footer date={dataCache}></Footer>
    </Container>
  )
}

export default MapaResumo